.topBarModalContainer, .topBarModalContent {
  width: 100%;
}

.topBarModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99990;
  display: table;
  background: rgba(34, 34, 34, 0.75);
}

.topBarModalHelper {
  display: table-cell;
  z-index: 1250;
}

.doubleDatePickerModal {
  width: 35%;
}

.singleDatePickerModal {
  width: 18%;
}
