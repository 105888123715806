// .aggregate-row>td, .aggregate-row>th {
//  background-color: #EDEDED !important;
// }
//
//
//
.ag-header-group-cell {
    text-align: center;

}
.ag-fresh .ag-header-group-cell-label {
    padding: 4px;
    font-weight: bold;
}

.ag-fresh .ag-root {
  border: 1px solid #808080;
  font-family: 'Roboto', sans-serif;
}

.ag-fresh .ag-visible-icons {
  cursor : pointer;
}


.list-unstyled,
.list-inline {
    padding-left: 0;
    list-style: none;
}

.dashboard-stats .count {
    font-size: 32px;
    line-height: 32px;
    height: 32px;
}

.dashboard-stats ul.inline li {
    vertical-align: middle;
}

.dashboard-stats .title {
  font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
}

.dashboard-stats ul.list-inline {
    margin-bottom:0px;
}

.dashboard-stats .stats-label {
    color: #999999;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 1px;
}

.dashboard-stats.small .count {
    font-size: 16px;
    line-height: 16px;
    height: 16px;
}

.dashboard-stats .progress {
    margin-bottom: 0;
    height: 5px;
}

.progress-blue {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#9bcff5), to(#6db9f0));
  background-image: -webkit-linear-gradient(top, #9bcff5, 0%, #6db9f0, 100%);
  background-image: -moz-linear-gradient(top, #9bcff5 0%, #6db9f0 100%);
  background-image: linear-gradient(to bottom, #9bcff5 0%, #6db9f0 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF9BCFF5', endColorstr='#FF6DB9F0', GradientType=0);
  border: 1px solid #55aeee; }

.ui-select-multiple.ui-select-bootstrap.form-control{
  min-height: 32px;
  padding: 4px 3px 0 3px;
}

.dropdown-menu {
    z-index: 999999;
}

md-radio-group {
    display: block;
    height: 32px;
}

.custom-select {
  // position: relative;
  padding: 0 10px;
  min-height: 32px;
  font-size: 13px;
  // display: block;
  line-height: 14px;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  height: auto;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.custom-select p {
  line-height: 32px;
  margin: 0;
}
.custom-select > span {
  position: absolute;
  right: 6px;
  top: 8px;
}
.custom-select-list {
  min-width:535px;
  // box-sizing: content-box;
  // position: absolute;
  // top: 32px;
  // left: -1px;
  // background-color: #FFF;
  // border-style: solid;
  // border-color: #cccccc;
  // border-width: 1px 1px 1px 1px;
  // width: auto;
  // overflow-x: hidden;
  max-height: 350px;
  overflow-y:auto;
  // -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  // box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
@media (max-width: 600px) {
  .custom-select-list {
    min-width: 0;
    width: 50%;
  }
}
.custom-select-list ul {
  list-style: none;
  padding: 0;
  width: 100%;
  margin: 0;
}

.custom-select-list ul li a {
  display: block;
  color: #333333;
  text-decoration: none;
  font-weight: bold;
  padding-top:2px;
  padding-bottom:2px;
}
.custom-select-list-group {
  font-weight: bold;
}

.custom-select-list ul li a span {
  padding-left: 15px;
  padding-right:15px;
}
.custom-select-list ul li a:hover {
    color: white;
    background-color: #337ab7
}
.custom-select-list ul li a.root {
  padding-left: 10px;
}
.custom-select-list ul li.active > a {
  background-color: #cccccc;
  color: #333333;
}
.custom-select-list ul li.forebears:not(.active) > a {
  border: 1px dotted #2e6077;
}
.custom-select-list ul li ul li a {
  font-weight: normal;
}
// .custom-select-list ul li ul li a span {
//   padding-left: 30px;
// }
// .custom-select-list ul li ul li li a span {
//   padding-left: 45px;
// }
// .custom-select-list ul li ul li li a li a span {
//   padding-left: 60px;
// }
.custom-select .icon-sort-down {
    display: inline-block;
    width: 0;
    height: 0;
    margin: 0px 0px 1px 12px  !important;
    vertical-align: middle;
    border-top: 4px solid #333;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    border-bottom: 0 dotted;
  // background: url('../img/icn.png') no-repeat 0 -12px #ffffff;
  // height: 20px;
  // width: 10px;
  // display: block;
}
.custom-select:focus {
  border-color: #1f1f1f;
}
.custom-select[disabled],
.custom-select[readonly],
fieldset[disabled] .custom-select {
  cursor: not-allowed;
  background-color: #eeeeee;
}

.arrow_box {
    position: absolute;
    border-radius: 3px;
    bottom: 9000%;
    background-color: rgba(217, 83, 79, 1);
    border: none;
    text-align: center;
    color: white;
    /*max-width: 80%;*/
    padding: 5px;
    opacity: 0;
    z-index: 0;
    -webkit-transition: opacity .5s ease-in;
    -moz-transition: opacity .5s ease-in;
    -o-transition: opacity .5s ease-in;
    -ms-transition: opacity .5s ease-in;
    transition: opacity .5s ease-in;
    &.active {
        bottom: 37px;
        opacity: 1;
        z-index: 9997;
    }
    &:after,
    &:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-width: 5px;
        left: 17px;
        margin-left: -5px;
    }
    &:before {
        border-color: rgba(0, 0, 0, 0);
        border-top-color: rgba(217, 83, 79, 1);
        border-width: 8px;
        left: 17px;
        margin-left: -8px;
    }
}

.left-inner-addon {
    position: relative;
    [data-form-item] {
        &.ng-valid.hasValue,
        &.ng-invalid.ng-dirty,
        &:required {
            padding-left: 30px;
        }
    }
    i {
        position: absolute;
        left: 0;
        top: 0;
        padding: 10px 10px;
        pointer-events: none;
        &.iconSmall {
            font-size: 8px;
            padding: 12px 12px;
        }
        &.fa {
            z-index: 200;
        }
    }
}

.right-inner-addon {
    position: relative;
    .form-control {
        padding-right: 30px;
    }
    i {
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px 10px;
        pointer-events: none;
        &.iconSmall {
            font-size: 8px;
            padding: 12px 12px;
        }
    }
}

.left-inner-addon i.iconSmall {
    font-size: 8px;
    padding: 12px 12px;
}

.right-inner-addo i.fa {
    z-index: 200;
}

.form-control {
    &:required {
        &.ng-valid {
            box-shadow: none;
            border-color: #cccccc;
            &.noValidate {
                box-shadow: none;
                border-color: #cccccc;
            }
            &.hasValue {
                box-shadow: none;
                border-color: #cccccc;
            }
            &.ng-dirty,
            &.hasValue.ng-dirty,
            &.ng-dirty {
                box-shadow: 0 0 5px #5cd053;
                border-color: #28921f;
                &.noValidate {
                    box-shadow: none;
                    border-color: #cccccc;
                }
            }
        }
        &.ng-invalid.ng-dirty {
            box-shadow: 0 0 5px #d45252;
            border-color: #b03535;
        }
    }
}

input.invalid {
    box-shadow: 0 0 5px #d45252;
    border-color: #b03535;
}

.ui-select-container[required].ng-invalid.ng-dirty {
    box-shadow: 0 0 5px #d45252;
    border-color: #b03535;
}

.ui-select-container[required].ng-valid.ng-dirty {
    box-shadow: 0 0 5px #5cd053;
    border-color: #28921f;
}

.ui-select-container[disabled][required].ng-invalid.ng-dirty {
    box-shadow: 0 0 5px #d45252;
    border-color: #b03535;
}

.form-control {
    &:required.ng-invalid.ng-dirty.disabled {
        box-shadow: none;
        border-color: #cccccc;
    }
    &[readonly] {
        cursor: not-allowed;
        background: #eeeeee no-repeat 4px center;
        box-shadow: none;
        border-color: #cccccc;
        padding-left: 12px;
    }
    &.clickable[readonly] {
        cursor: pointer;
    }
    &:required.ng-valid {
        &[readonly],
        &.hasValue[readonly],
        &[readonly] {
            cursor: not-allowed;
            background: #eeeeee no-repeat 4px center;
            box-shadow: none;
            border-color: #cccccc;
            padding-left: 12px;
        }
    }
    &[disabled] {
        cursor: not-allowed;
        background: #eeeeee no-repeat 4px center;
        box-shadow: none;
        border-color: #cccccc;
        padding-left: 12px;
    }
    &:required.ng-valid {
        &[disabled],
        &.hasValue[disabled],
        &[disabled] {
            cursor: not-allowed;
            background: #eeeeee no-repeat 4px center;
            box-shadow: none;
            border-color: #cccccc;
            padding-left: 12px;
        }
    }
}

.table>thead>tr>th {
    -khtml-border-radius: 0;
    border-radius: 0;
    line-height: normal;
    border-bottom: 1px solid #D5D5D5;
    @include gradient-vertical(#fafafa, #e9e9e9);
    height: 34px;
    // padding: 0;
    // line-height: 40px;
    .clickable:hover {
        text-decoration: underline;
    }
}

.group-table > tbody > tr.aggregate-row:hover > {
    td,
    th {
        cursor: pointer;
        background-color: #FDFDCE;
    }
}


/*
IMPORTANT:
In order to preserve the uniform grid appearance, all cell styles need to have padding, margin and border sizes.
No built-in (selected, editable, highlight, flashing, invalid, loading, :focus) or user-specified CSS
classes should alter those!
*/

.slick-header.ui-state-default,
.slick-headerrow.ui-state-default {
    width: 100%;
    overflow: hidden;
    border-left: 0px;
}

.slick-header-columns,
.slick-headerrow-columns {
    position: relative;
    white-space: nowrap;
    cursor: default;
    overflow: hidden;
}

.slick-header-column.ui-state-default {
    position: relative;
    display: inline-block;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    height: 16px;
    line-height: 16px;
    margin: 0;
    padding: 4px;
    border-right: 1px solid silver;
    border-left: 0px;
    border-top: 0px;
    border-bottom: 0px;
    float: left;
}

.slick-headerrow-column.ui-state-default {
    padding: 4px;
}

.slick-header-column-sorted {
    font-style: italic;
}

.slick-sort-indicator {
    display: inline-block;
    width: 8px;
    height: 5px;
    margin-left: 4px;
    margin-top: 6px;
    float: left;
}

.slick-sort-indicator-desc {
    background: url(images/sort-desc.gif);
}

.slick-sort-indicator-asc {
    background: url(images/sort-asc.gif);
}

.slick-resizable-handle {
    position: absolute;
    font-size: 0.1px;
    display: block;
    cursor: col-resize;
    width: 4px;
    right: 0px;
    top: 0;
    height: 100%;
}

.slick-sortable-placeholder {
    background: silver;
}

.grid-canvas {
    position: relative;
    outline: 0;
}

.slick-row {
    &.ui-widget-content,
    &.ui-state-active {
        position: absolute;
        border: 0px;
        width: 100%;
    }
}

.slick-cell,
.slick-headerrow-column {
    position: absolute;
    border: 1px solid transparent;
    border-right: 1px dotted silver;
    border-bottom-color: silver;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    vertical-align: middle;
    z-index: 1;
    padding: 1px 2px 2px 1px;
    margin: 0;
    white-space: nowrap;
    cursor: default;
}

// .slick-group! {}
.slick-group-toggle {
    display: inline-block;
}

.slick-cell {
    &.highlighted {
        background: lightskyblue;
        background: rgba(0, 0, 255, 0.2);
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
    }
    &.flashing {
        border: 1px solid red !important;
    }
    &.editable {
        z-index: 11;
        overflow: visible;
        background: white;
        border-color: black;
        border-style: solid;
    }
    &:focus {
        outline: none;
    }
}

.slick-reorder-proxy {
    display: inline-block;
    background: blue;
    opacity: 0.15;
    filter: alpha(opacity=15);
    cursor: move;
}

.slick-reorder-guide {
    display: inline-block;
    height: 2px;
    background: blue;
    opacity: 0.7;
    filter: alpha(opacity=70);
}

.slick-selection {
    z-index: 10;
    position: absolute;
    border: 2px dashed black;
}

#imp i.fa.text-danger.fa-exclamation-triangle {
    top: -33px;
}

#imp i.fa.text-success.fa-check {
    top: -33px;
}

#imp ul.select2-choices {
    padding-left: 30px;
}

#imp .select2-container.select2-container-multi.form-control.ng-untouched.ng-isolate-scope.ng-dirty.ng-valid-parse.has-success.ng-valid.ng-valid-required {
    box-shadow: 0 0 5px #5cd053 !important;
}

#imp2 i.fa.fa-asterisk.text-danger.iconSmall {
    visibility: hidden;
}

.legendColorBox td{
    height:10px;
    width:10px
}
.legendLabel{
    font-size:12px;
    padding-left:5px;
}

.horizontalLegend .legendLabel {
    padding-right:20px;
}

.horizontalLegend table{
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.filter-checkbox {
  margin-top: 25px;
  font-weight: bold;
}
.date-period-main{
    width:100%;
    height: 40px;
    padding:2px 8px;
    background-color: #f5f5f5;
    border:.5px solid #ddd;
    
    }
.display-dates{
    
    margin: 20px 0 40px 0;
}
.show-change{
    animation: textChangeFade .5s ease-in
}
.date-period{
    padding: 4px 2px;
    font-size: 14px;
    color: #177bbb;
    border-top: 0; 
    border-left:0; 
    border-right:0; 
    border-bottom: 1px solid #c6c6c6;
    border-radius: 5px;
    max-width: 100%;
}
.date-period-display{
    padding-top: 6px;
    font-size: 14px;
    color: #177bbb;
}
.date-period-container{
    padding-top: 2px;
    float: right;
    }
.dateForDisplay{
    padding: 5px;
}
@keyframes textChangeFade{
    0%{
        background-color: #8db7fc;
       
        border-radius: 5px;
        }
    50%{
        background-color: #cfdef7;
      
        border-radius: 15px;
        } 
    100%{
        background-color: transparent;
       
        border-radius: 25px;
        }
}

.ui-select-multiple input.ui-select-search{
    width: 100% !important;
    position: absolute;
}

.page-area {
    display: flex;
    justify-content: center;
    height: 100%;
    background-color: #eee;
}

.content {
    max-width: 1400px;
    padding: 16px;
    box-sizing: border-box;
}

.header {
    padding: 16px;
    border-bottom: 1px solid #ddd;
}


.add-button {
    margin-left: 16px;
}

.action-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-button-container > .btn {
    margin-right: 5px;
}

.action-button-container > .btn:last-child {
    margin-right: 0px;
}

td.wrapped-column {
   max-width: 500px; 
   white-space: normal !important;
   word-wrap: break-word;
   word-break: break-word;
}