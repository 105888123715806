div.tempDivRounded {
    -moz-border-radius: 20px;
    border-radius: 15px;
    height: 100%;
    width: 100%;
}
div.temp {
    font-size: 24px;
}
div.tempRange {
    background-color: #1e90ff;
    border-radius: 12px;
    width: 15px;
    margin: 0 auto 0 auto;
}
.font10 {
    font-size: 10px;
}
th {
    height: 30px !important;
}
figure {
    margin: 3em auto;
    width: 368px;
    height: 152px;
}