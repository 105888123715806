.input-margin {
  margin: 5px 0;
}

.formSection {
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
  -webkit-transition: all .5s ease;
  display: none;
  left: 150%;
  position: absolute;
  transition: all .5s ease;
  width: 100%;
  &.active {
    display: block;
    left: 0;
    z-index: 3000;
  }
}

.check-button {
  float: left;
  padding-top: 0px;
}

.select-label {
  font-size: 15px;
  line-height: 2;
}

.blue-color {
  background: rgb(82, 147, 196);
}

.icon-style {
  width: 20px;
  margin-right: 5px;
  color: rgb(82, 147, 196);
}

.overflow-col {
  text-overflow: ellipsis;
}


.ml-0 {
  margin-left: 0px;
}

input.time {
  width: auto;
}