.quotes-flot-graph div.xAxis div.tickLabel {
  -webkit-transform: rotate(25deg);
  -moz-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  -o-transform: rotate(25deg);
  transform: rotate(25deg);
  top: 10px;
}

.carousel-indicators {
  display: none;
}

.carousel-control {
  cursor: pointer;
  &.left, &.right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  }
  .glyphicon {
    &.glyphicon-chevron-right {
      left: 10px;
      color: #333;
    }
    &.glyphicon-chevron-left {
      color: #333;
    }
  }
}