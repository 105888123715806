
$header-background-color: #1e88e5;
$header-hover-color: #2196f3;
$header-active-color: #42a5f5;

$header-border-color: #a1a1a1;

$header-height: 64px;

#header {
    z-index: 6 !important;
}

.header-container {
    flex-direction: row;
    align-items: center;

    padding: 0 10px;
    background-color: $header-background-color;
    min-height: $header-height;
}


#main-menu-button:hover {
    background-color: $header-hover-color !important;
}

#main-menu-button {

    .line-wrap {
        margin-left: 3px;
        width: 18px;
        height: 12px;

        .line{
            width: 18px;
            height: 2px;
            background-color: white;

            &.center {
                margin: 3px 0;
            }
        }
    }
}

.active-item-title {
    font-size: 20px;
    font-weight: 400;
    color: white;
    margin: 0 10px;
}

/* CSS Specific to the User Menu in the Upper Right of the Header */
#user-menu {
    margin-left: auto;
    margin-right: 10px;
    color: white;
    font-size: 14px;
    font-weight: 500;

    /* Button in Header */
    button {
        border-radius: 5px;
        cursor: pointer;
    }

    button:hover {
        background-color: $header-hover-color;
    }

   

}

#user-menu.md-open {
    button {
        background-color: $header-active-color;
    }
}

 /* Menu Content -> Does not have #user-menu as a parent */
 .md-open-menu-container {
    border-radius: 5px;
 }
 
 md-menu-content.user-menu-content {
    background-color: $header-hover-color;
    justify-content: space-evenly;
    border-radius: 5px;
    min-width: 200px;

    md-menu-item {
        align-items: center;
        margin: 5px;
    }

    button {
        color: white;
        font-size: 14px;
        font-weight: 500;
        border-radius: 5px;
    }

    button:hover {
        background-color: $header-active-color !important;
    }

    button > i {
        margin-right: 5px;
    }
}

