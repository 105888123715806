
$fullscreen-zindex: 2000 !default;

.high {
  height: 100%;
}

.fullscreen {
  position: fixed !important;
  top     : 0  !important;
  bottom  : 0 !important;
  left    : 0 !important;
  right   : 0 !important;
  margin  : 0 !important;
  width   : 100% !important;
  height  : 100% !important;
  z-index : $fullscreen-zindex !important;
}

.push-top {
  margin-top: $panel-body-padding;
}

.push-bottom {
  margin-bottom: $panel-body-padding;
}

.pull-top {
  margin-top: -$panel-body-padding;
}

.vpadded {
  padding-top: $panel-body-padding;
  padding-bottom: $panel-body-padding;
}

.vpadded-row {
  > * {
    padding-top: $panel-body-padding;
    padding-bottom: $panel-body-padding;
  }
}

.hpadded {
  padding-left: $panel-body-padding;
  padding-right: $panel-body-padding;
}

.padded {
  padding: $panel-body-padding;
}

.no-padding {
  padding: 0;
}

.margin {
  margin: $panel-body-padding;
}

.no-margin {
  margin: 0;
}

$positions: top bottom left right;
@each $position in $positions {
  .padded-#{$position} {
    padding-#{$position}: $panel-body-padding;
  }
  .margin-#{$position} {
    margin-#{$position}: $panel-body-padding;
  }
  .no-padding-#{$position} {
    padding-#{$position}: 0;
  }
  .no-margin-#{$position} {
    margin-#{$position}: 0;
  }
}

.no-padding-v {
  padding-top: 0;
  padding-bottom: 0;
}

.no-padding-h {
  padding-left: 0;
  padding-right: 0;
}

.no-border-radius {
  border-radius: 0;
}

.vertical-text-left {
  position: absolute;
  left: 1.5em;
  @include transform(rotate(90deg));
  @include transform-origin(left top 0);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.vertical-text-right {
  position: absolute;
  right: 1.5em;
  @include transform(rotate(-90deg));
  @include transform-origin(right top 0);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  &.with-scroll {
    right: 2em;
  }
}

.circle {
  @include border-top-radius(50%);
  @include border-right-radius(50%);
  @include border-bottom-radius(50%);
  @include border-left-radius(50%);
}

.grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\\'http://www.w3.org/2000/svg\\'><filter id=\\'grayscale\\'><feColorMatrix type=\\'matrix\\' values=\\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
  filter: gray; /* IE6-9 */
  @include filter(grayscale, 100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}

.page-center-parent {
  display: table;
  width: 100%;
  height: 100%; }

.page-center {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}


@mixin transition($transition) {
  -webkit-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}
@mixin transition-property($transition-property) {
  -webkit-transition-property: $transition-property;
          transition-property: $transition-property;
}
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
          transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration) {
  -webkit-transition-duration: $transition-duration;
          transition-duration: $transition-duration;
}
@mixin transition-timing-function($timing-function) {
  -webkit-transition-timing-function: $timing-function;
          transition-timing-function: $timing-function;
}
@mixin transition-transform($transition) {
  -webkit-transition: -webkit-transform $transition;
     -moz-transition: -moz-transform $transition;
       -o-transition: -o-transform $transition;
          transition: transform $transition;
}

@mixin scale3d($x, $y, $z) {
   @include transform(scale3d($x, $y, $z));
}



/*
 * Pop-in Hover effects
 */
@mixin hover-pop($background: "rgba(0,0,0,0.5)", $radius: 0, $duration: 250ms, $zindex: 0) {
    @media (min-width: $screen-sm-min) {
        position: relative;

        &:before {
            left: 0;
            top: 0;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            @include scale3d(0,0,0);
            @include transition(all);
            @include transition-duration($duration);
            @include backface-visibility(hidden);
            background-color: $background;
            z-index: $zindex;
            border-radius: $radius;
            @include opacity(0);
        }

        &:hover:before,
        &.open:before {
            @include scale3d(1,1,1);
            @include opacity(1);
        }
    }
}
// Transformations
@mixin scale($ratio) {
  -webkit-transform: scale($ratio);
      -ms-transform: scale($ratio); // IE9 only
       -o-transform: scale($ratio);
          transform: scale($ratio);
}

@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
          transform: translate3d($x, $y, $z);
}

@mixin user-select($select) {
  -webkit-user-select: $select;
     -moz-user-select: $select;
      -ms-user-select: $select; // IE10+
          user-select: $select;
}


//helpers for pulling content. useful when dealing with first or last col-* in a row, or want to pull up/down stuff
$screen-widths-max:
  xs $screen-xs-max,
  sm $screen-sm-max,
  md $screen-md-max;

@mixin pull-content($size-name, $space) {
  .pull-left-#{$size-name} {
    margin-left: - floor($space);
  }

  .pull-right-#{$size-name} {
    margin-right: - floor($space);
  }

  .pull-top-#{$size-name} {
    margin-top: - floor($space);
  }

  .pull-bottom-#{$size-name} {
    margin-bottom: - floor($space);
  }

  .push-left-#{$size-name} {
    margin-left: floor($space);
  }

  .push-right-#{$size-name} {
    margin-right: floor($space);
  }

  .push-top-#{$size-name} {
    margin-top: floor($space);
  }

  .push-bottom-#{$size-name} {
    margin-bottom: floor($space);
  }
}

@include pull-content(lg, $grid-gutter-width/2);

@each $pair in $screen-widths-max {
  @media (max-width: nth($pair, 2)) {
    @include pull-content(nth($pair, 1), $grid-gutter-width/2);
    .no-pull-#{nth($pair, 1)} {
      margin: 0;
    }
  }
}

// center a component between pull-left and pull-right
.pull-center {
  display: table;
  margin-left: auto;
  margin-right: auto;
  float: none;
}

.component-shadow {
  @include box-shadow($component-shadow);
}
.text-component-shadow {
  text-shadow :$component-shadow;
}


.width-full {
  width: 100%;
}

.height-full {
  height: 100%;
}

.max-form-width {
  max-width: 120rem;
}


/* FLEX */
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-space-between {
  justify-content: space-between;
}