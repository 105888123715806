
.tab-menu {
  margin          : 0 auto;
  padding         : 0;
  list-style      : none;
  text-align      : center;
 // @include gradient-vertical(#607d8b, darken(#607d8b, 10%));
  background: #1d354d;
   @include box-shadow(inset 0 1px 0 #28496b);
   //border-bottom: 1px solid #000000;
  text-transform  : uppercase;
  > li {
    padding : 0;
    position: relative;
    display : inline-block;
  }
  a {
    text-decoration: none;
    color          : white;
    &:hover, &:focus {
      text-decoration: none;
    }
  }
  > li {
    > a {
      display: inline-block;
      width  : auto;
      height : 30px;
      padding: 5px 10px 5px 10px;
      opacity: 0.8;
      &:hover {
        text-decoration: none;
        border-bottom: 2px solid rgb(243, 113, 22);//#80d8ff
        opacity: 1;
      }
    }
    &.tab-menu-active > a {
      opacity      : 1;
      border-bottom: 2px solid rgb(243, 113, 22); //#80d8ff
    }
  }
}







/* 
	dropdown basic CSS style is credit of bootstrap CSS
	http://getbootstrap.com/
*/

.tab-menu-dropdown {
  background-color  : #607d8b;
  top               : 30px;
  right             : 0;
  left              : auto;
  position          : absolute;
  z-index           : 9000;
  display           : none;
  float             : left;
  min-width         : 160px;
  padding           : 5px 0;
  margin            : 2px 0 0;
  font-size         : 14px;
  list-style        : none;
  border            : 1px solid #cccccc;
  border            : 1px solid rgba(0, 0, 0, 0.15);
  border-radius     : 2px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow        : 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip   : padding-box;
  li {
    text-align: left;
    font-size: 12px;
    a {
      color      : white;
      opacity    : 0.8;
      display    : block;
      padding    : 3px 20px;
      clear      : both;
      font-weight: normal;
      line-height: 1.428571429;
      white-space: nowrap;
      &:hover {
        text-decoration : none;
        background-color: #78909c;
        opacity         : 1;
      }
    }
  }
}

.tab-menu-dropdown-open ~ .tab-menu-dropdown {
  display: block;
}