.detailViewContent {
    height: calc(100% - 34px);
    width: 100%;
    overflow: auto;
    position:relative;
    z-index: 99;
    border-right: 1px solid #D5D5D5;
    @include gradient-horizontal(#fafafa, #e9e9e9);
     .panel {
         border-color: #ddd;
         .panel-heading {
             line-height: normal;
             border-bottom: 1px solid #ddd;
             @include gradient-vertical(#fafafa, #e9e9e9);
         }
     }
    .list-group-item.active {
        color: white;
        background: rgb(125, 125, 125);
        border-color: rgb(125, 125, 125);
    }
}

.detailViewContent {
    .nav-pills > li.active > a {
        color: #ffffff;
        background-color: #333;
        &:hover, &:focus {
            color: #ffffff;
            background-color: #333;
        }
    }
}

.panel-footer {
    background-color: #ffffff;
}

.detailWidgetView {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    width: 100%;
}

.detailViewTab {
    .widget-title {
        width: 180px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        height: 32px;
        border: 1px solid rgba(255, 255, 255, 0.9);
        margin: 1px 1px;
        border-radius: 2px;
    }
    .overlay {
        height: 34px;
        background-color: rgba(255, 255, 255, 0.5);
    }
    &.maximized .overlay {
        background: none;
    }
    .widget-title .widget-name {
        width: 116px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
