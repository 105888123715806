$taskbar-background-color: #202124;

$taskbar-hover-color: #303134;
$taskbar-active-item-color: #3c4043;
$taskbar-active-item-hover-color: #4e5356;

$taskbar-font-color: #bdc1c6;

$footer-height : 40px;


/*** Taskbar ***/
.taskbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;

    min-height: $footer-height;
    
    background-color: $taskbar-background-color;
}

.taskbar-divider {
    border-left: 1px solid $taskbar-font-color;
    height: $footer-height - 10;
    margin: 0 10px;
}



/*** Tabs ***/
.taskbar-tabs-container {
    display: flex;
    flex-wrap: wrap;

    flex: 1;  

    margin-left: 20px;
}

.taskbar-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;

    flex: 1 1 90px;
    max-width: 200px;
    min-width: 90px;

    color: $taskbar-font-color;

    height: $footer-height - 10;
    margin: 5px 0px;
    margin-left: 5px;
    
    padding-left: 5px;
    border-radius: 5px;
}

.taskbar-tab-content {
    display: flex;
    align-items: center;

    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    

    md-icon {
        color: $taskbar-font-color;
        font-size: 13px;
        display: flex;
        align-items: center;
        min-width: 13px;
        max-width: 13px;
    }
}

.taskbar-tab:hover {
    background-color: $taskbar-hover-color;
    cursor: pointer;
}

.taskbar-tab.active-tab {
    background-color: $taskbar-active-item-color;
}


.taskbar-tab-close-button {
    cursor: pointer;
    font-weight: bold;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    font-family: Arial;

    display: flex;
    justify-content: center;
    align-items: center;
}

.taskbar-tab-close-button:hover {
    background-color: $taskbar-active-item-color;
}

.active-tab .taskbar-tab-close-button:hover {
    background-color: $taskbar-active-item-hover-color;
}



/*** Tray ***/
.taskbar-tray {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    
     margin-right: 20px;

}

.taskbar-tray-icon {
    margin: 0px 10px;
}

.taskbar-tray-icon a {
    color: $taskbar-font-color;
}
