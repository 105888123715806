/* Window */
.angus-window {
    display:flex;
    flex-direction: column;
    height: 100%;

}

/* Titlebar */
$titlebar-button-hover-color: rgba(64,64,64,.25);
$titlebar-font-color: #43494d;

.angus-window > .window-title-bar {
    display: flex;
    align-items: center;

    height: 35px;
    color: $titlebar-font-color;
    border-bottom: 1px solid #d5d5d5;
    background-image: linear-gradient(to bottom, #fafafa 0, #e9e9e9 100%);

    font-size: 14px;

    i {
        margin: 0 15px;
    }

    .window-title {
        flex: 1;
    }

    .title-button {
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        width: 35px;
        height: 35px;
        font-size: 14px;
        font-weight: 700;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }

    .title-button-minimize {
        padding-bottom: 5px;
    }

    .title-button:hover {
        background-color: $titlebar-button-hover-color;
    }

    .title-button-close:hover {
        background-color: rgb(200, 0, 0);
        color: #f7f7f7;
    }
    
}
  

/* Content */
.angus-window > .window-content {
    background-color: #f7f7f7;
    flex: 1;
    overflow: auto;
}