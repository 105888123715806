.table-spacing {
  margin-top: 15px;
  .center {
    margin-bottom: 12px;
  }
}

.units {
  font-size: 12px;
}

.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.indicators {
  font-size: 20px;
}

.date-selector {
  font-size: 11px;
  position: absolute;
  margin: 25px;
}

.period-value {
  font-size: 14px;
}

.driver-nav-list {
  list-style: none;
  float: right;
  -moz-min-width: 400px;
  -ms-min-width: 400px;
  -o-min-width: 400px;
  -webkit-min-width: 400px;
  min-width: 400px;
}

.driver-nav-item {
  width: 150px;
  float: right;
}

.flot-graph div.xAxis div.tickLabel {
  -webkit-transform: rotate(25deg);
  -moz-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  -o-transform: rotate(25deg);
  transform: rotate(25deg);
  top: 10px;
}

.report-indicator {
  padding: 3px 0 0 15px;
  float: left;
}

.hide-table-item {
  display: none;
}

.date-picker-container {
  margin-right: 15px;
  width: 50px;
  left: 0;
  position: relative;
}