.profileContainer {
  position: relative;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  display: table;
  background: rgba(34, 34, 34, 0.75);
}

.edit-profile-icon {
  font-size: 14px;
  position: absolute;
  padding: 4px 5px 5px 5px;
}
