.centerBarModalContainer, .centerBarModalContent {
  width: 100%;
}

.centerBarModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 5000;
  display: table;
  background: rgba(34, 34, 34, 0.75);
}

.centerBarModalHelper {
  display: table-cell;
  vertical-align: middle;
  z-index: 1250;
}

.centerBarModalContent {
  position: relative;
  margin-top: -145px;
  background: rgb(34, 34, 34);
  z-index: 5000;
  color: white;
  text-align: center;
  vertical-align: middle;
}

.modelCloseButton {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}