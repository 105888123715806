.toast-container {
    position: absolute;
    // bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1050;
    pointer-events: none;

  }


  .toast-container .toast {
    width: 100%;
    max-width: 800px;
    margin: 8px;
    box-sizing: border-box;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    pointer-events: auto;
  }
  