.ag-angus .ag-root {
  border: 1px solid #808080;
  // font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-family: Roboto,"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 13px;
}
.ag-angus .ag-cell {
  padding: 2px;

  -webkit-user-select:text;
  -moz-user-select:text;
  -ms-user-select:text;
  user-select:text;
}


.ag-angus .ag-column-moving .ag-cell {
  -webkit-transition: left 0.2s;
  -moz-transition: left 0.2s;
  -o-transition: left 0.2s;
  -ms-transition: left 0.2s;
  transition: left 0.2s;
}
.ag-angus .ag-cell-focus {
  border: 1px solid #a9a9a9;
}
.ag-angus .ag-cell-no-focus {
  border-right: 1px dotted #808080;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.ag-angus .ag-cell-first-right-pinned {
  border-left: 1px dotted #808080;
}
.ag-angus .ag-header {
  background: -webkit-linear-gradient(#fff, #d3d3d3);
  background: -moz-linear-gradient(#fff, #d3d3d3);
  background: -o-linear-gradient(#fff, #d3d3d3);
  background: -ms-linear-gradient(#fff, #d3d3d3);
  background: linear-gradient(#fff, #d3d3d3);
  border-bottom: 1px solid #808080;
}
.ag-angus .ag-no-scrolls .ag-header-container {
  background: -webkit-linear-gradient(#fff, #d3d3d3);
  background: -moz-linear-gradient(#fff, #d3d3d3);
  background: -o-linear-gradient(#fff, #d3d3d3);
  background: -ms-linear-gradient(#fff, #d3d3d3);
  background: linear-gradient(#fff, #d3d3d3);
  border-bottom: 1px solid #808080;
}
.ag-angus .ag-header-cell {
  border-right: 1px solid #808080;
  font-weight: bold;
  cursor: pointer;
}
.ag-angus .ag-header-cell-moving .ag-header-cell-label {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.ag-angus .ag-header-cell-moving {
  background-color: #bebebe;
}
.ag-angus .ag-header-cell-moving-clone {
  border-right: 1px solid #808080;
  border-left: 1px solid #808080;
  background-color: rgba(220,220,220,0.8);
}
.ag-angus .ag-header-group-cell {
  border-right: 1px solid #808080;
}
.ag-angus .ag-header-group-cell-with-group {
  border-bottom: 1px solid #808080;
}
.ag-angus .ag-header-cell-label {
  padding: 4px 2px 4px 2px;
}
.ag-angus .ag-header-cell-text {
  padding-left: 2px;
}
.ag-angus .ag-header-group-cell-label {
  padding: 4px;
  padding-left: 10px;
  font-weight: bold;
}
.ag-angus .ag-header-group-text {
  margin-right: 2px;
}
.ag-angus .ag-header-cell-menu-button {
  padding: 2px;
  margin-top: 4px;
  border: 1px solid transparent;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; /* When using bootstrap, box-sizing was set to 'border-box' */
  line-height: 0px; /* normal line height, a space was appearing below the menu button */
}
.ag-angus .ag-pinned-right-header {
  border-left: 1px solid #808080;
}
.ag-angus .ag-header-cell-menu-button:hover {
	border: 1px solid #808080;
	padding-bottom: 4px;
}
.ag-angus .ag-header-icon {
  color: #686D70;
}

.ag-angus .ag-row-selected {
  background-color: rgb(228, 252, 255) !important
}
.ag-angus .ag-body .ag-row-odd {
  background-color: #f6f6f6;
}
.ag-angus .ag-body .ag-row-even {
  background-color: #fff;
}
.ag-angus .ag-floating-top .ag-row {
  background-color: #f0f0f0;
}
.ag-angus .ag-floating-bottom .ag-row {
  background-color: #f0f0f0;
}
.ag-angus .ag-overlay-loading-wrapper {
  background-color: rgba(255,255,255,0.5);
}
.ag-angus .ag-row:hover {
  background-color: #FDFDCE;
}
.ag-angus .ag-row:hover > .ag-cell {
  background-color: #FDFDCE !important;
}

.ag-angus .ag-overlay-loading-center {
  background-color: #fff;
  border: 1px solid #a9a9a9;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;
}
.ag-angus .ag-overlay-no-rows-center {
  background-color: #fff;
  border: 1px solid #a9a9a9;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;
}
.ag-angus .ag-body {
  background-color: #fafafa;
}
.ag-angus .ag-group-cell-entire-row {
  background-color: #aaa;
  padding: 4px;
}
.ag-angus .ag-footer-cell-entire-row {
  background-color: #aaa;
  padding: 4px;
}
.ag-angus .ag-group-cell {
  font-style: italic;
  font-weight: bold;
}
.ag-angus .ag-group-expand {
  padding-right: 5px;
}
.ag-angus .ag-footer-cell {
  font-style: italic;
}
.ag-angus .ag-filter-checkbox {
  position: relative;
  top: 2px;
  left: 2px;
}
.ag-angus .ag-filter-header-container {
  border-bottom: 1px solid #d3d3d3;
  margin:4px;
}
.ag-angus .ag-filter-apply-panel {
  border-top: 1px solid #d3d3d3;
  padding: 2px;
}
.ag-angus .ag-filter {
  border: 1px solid #000;
  background-color: #f0f0f0;
  padding: 5px;
}
.ag-angus .ag-filter-value {
  margin-left: 4px;
}
.ag-angus .ag-selection-checkbox {
  margin-left: 4px;
}
.ag-angus .ag-paging-panel {
  padding: 4px;
}
.ag-angus .ag-paging-button {
  margin-left: 4px;
  margin-right: 4px;
}
.ag-angus .ag-paging-row-summary-panel {
  display: inline-block;
  width: 300px;
}

.ag-angus .ag-filter-filter {
	width:100% !important;
	margin:0;
}
.ag-angus .ag-column-not-visible {
  opacity: 0.75;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
  filter: alpha(opacity=75);
}
.ag-angus .ag-not-dragging {
  border: 1px solid transparent;
}
.ag-angus .ag-drop-target-above {
  border-top: 5px solid #a9a9a9;
}
.ag-angus .ag-drop-target-below {
  border-bottom: 5px solid #a9a9a9;
}
.ag-angus .ag-dragging {
  border: 1px dotted #a9a9a9;
}
.ag-angus .ag-list-item-selected {
  color: #f0f0f0;
}
.ag-angus .ag-list-item-not-selected {
  font-style: italic;
  color: #a0a0a0;
}
.ag-angus .ag-tool-panel-container {
  background-color: #eee;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #a9a9a9;
  padding: 4px;
}
.ag-angus .ag-list-selection {
  background-color: #fff;
  border: 1px solid #a9a9a9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ag-angus .ag-popup-list .ag-list-item:hover {
  background-color: #add8e6;
}
.ag-angus .ag-visible-icons {
  padding-left: 2px;
  padding-right: 2px;
}


.ag-angus .ag-filter-select {
	margin: 0px;
	margin-bottom: 3px;
}

.ag-angus .ag-filter-list-viewport {
	width: 220px;
}

md-checkbox.md-checked.green-colored .md-icon {
    background-color: #179877;
}

.md-checkbox-filter-margins {
    margin: 6px 0 0 35px;
}