#side-menu-container {
    position: fixed;
    top: -120px;
    right: 0;
    bottom: -120px;
    left: 0;

    z-index: 9999;

    visibility: hidden;
    transition: visibility 200ms ease;
}

/* Side Menu Open */
#side-menu-container.side-menu-open {
    visibility: visible;
    
    #scrim {
        opacity: 1;
    }

    #side-menu-content {
        transform: translate3d(0, 0, 0);
    } 
}

#scrim {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    opacity: 0;
    transform: translateZ(0);

    transition: opacity 200ms;

    background: rgba(0, 0, 0, 0.5);
}

#side-menu-content {
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    padding: 120px 0;
    
    width: $side-menu-left-width;

    background: #FFF;
    box-shadow: 0 0 10px rgba(51, 51, 51, .38);

    transition: transform 200ms ease;
    transform: translate3d(-100%, 0, 0);

}

#side-menu-header {
    flex: 0 0 $header-height;
    background-color: $header-background-color;

    display: flex;
    align-items: center;

    padding-left: 10px;
}

#profile-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    

    .profile-pic {
        display: flex;
        justify-content: center;
        
        height: 115px;
        width: 100%;
        padding: 7px 20px;
        
        // color:#FCFCFC ;
        background: #177BBB url("/img/userbox-bg.png") left 40%;
        background-size: 100%;        
    }

    .profile-info {
        display: flex;
        justify-content: center;
        align-items: center;
        
        // cursor: pointer;

        background-color: #a1a1a1;
        color: #fff;

        width: 100%;
        padding: 7px 14px;
    }
}

#side-menu {
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0;

    .sub-menu-group {
        border-bottom: 1px solid #E6E6E6;
        display: flex;
        flex-direction: column;
    }

    .sub-menu-group.toggled {
        .sub-menu-button-caret {
            transform: rotate(180deg);
        }
    }

    .sub-menu-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 15px 20px;
        cursor: pointer;

        .sub-menu-button {
            color: #757575;
        }

        .sub-menu-button-icon {
            width: 20px;

            md-icon {
                display: flex;
                align-items: center;
                font-size: 20px;
            }

            img {
                width: 20px;
            }
        }

        button {
            display: none;
            padding: 0 12px;
        }

        &.active-dashboard {
            .sub-menu-button-content {
                font-weight: 700;
                color: #177bbb;
            }
        }

        &:hover {
            button {
                display: block;
            }
        }

        #text {
            flex: 1;
            color: #757575;
            font-size: 13px;
            font-weight: 500;
        }
        
        .sub-menu-button-content {
            flex: 1;
            color: #757575;
            font-size: 13px;
            font-weight: 500;
            padding-left: 10px;
        }

        .sub-menu-button-caret {
            color: #757575;
            transform: rotate(0);
            transition: all 300ms;
        }

        &:hover {
            background-color: #e9e9e9;

            .sub-menu-button-icon md-icon {
                color: #262626;
            }

            .sub-menu-button-content {
                color: #262626;
            }

            .sub-menu-button-caret {
                color: #262626;
            }
    

          
        }
    }

    .impersonation-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 15px 20px 15px 44px;
        cursor: pointer;

        &:hover {
            background-color: #e9e9e9;
        }
    }

    
}