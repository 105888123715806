.widget-flex-container {
	display: flex;
	flex-grow: 1;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
}

.widget-flex-row {
	display: flex;
	flex-grow: 1;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
}

.widget-flex-column {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}


.widget-flex {
	display: flex;
	flex-grow: 1;
	alignment-baseline: central;
    justify-content: center;
    align-content: center;
    align-items: center;	
}



.widget-flex-hidden-xs-short {
	display: flex !important;
}

/* Polyfill */


/* SHORT */
.widget-card-template[max-height~="400px"][max-width~="600px"] .widget-flex-hidden-xs-short{    	
	display: none !important;
}

/* TALL */




