.bggrey{
  padding: 30px 0 5px 0;
}

ul.hddText{
  list-style-type:none;
}

div.hddDiff{
  font-size:3.6em;
  color: #428BCA;
}

div.meterbar{
  padding: 0 0 40px 0;
  width: 90%;
}

div.barcontainer{
  width: 100px;
}

div.centerbymargin{
  margin: 0 auto;
  width: 98%;
  padding: 10px 0 0 0;
}

div.progress{
  border-radius: 8px !important;
/*  border-color: none !important;*/
}

div.bar{
  background-color: #428BCA;
}

ul.nobullets{
    list-style-type:none;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
}

.warmerClass {
        background-color:#FF2954 !important;
        border: 2px solid #FF2954 !important;
        color:#FF2954 !important;
}
 
 .red{
        color:#FF2954 !important;  
 }

.colderClass {
        background-color:#3B91ED !important;
        border: 2px solid #3B91ED !important;
}

.blue {
        color:#3B91ED !important;
}

.negligibleClass {
        background-color:#FFFFFF !important;
        border: 2px solid #FFFFFF !important;
}

.warmerfontClass {
        color:#FF2954 !important;
}
 
.colderfontClass {
        color:#3B91ED !important;
}

.negligiblefontClass {
        color:#CCC !important;
}
