.widgetsContainer {
  position: relative;
  margin: 0 auto;
  /*background: #fff;*/
  border-radius: 5px;
}

.widget {
  // position: absolute;

  //z-index: 500;
  // display: inline-block;


    // border: 1px solid #D5D5D5;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);

  &.active {
    top: 0;
    opacity: 1;
  }
  &.hidden {
    top: -200%;
    opacity: 0;
  }
  &.previous {
    top: -200%;
    opacity: 1;
  }
  &.next {
    top: 200%;
    opacity: 1;
  }
}

.widget-title {
  -khtml-border-radius: 0;
  cursor: move;
  border-radius: 0;
  line-height: normal;
border-bottom: 1px solid #D5D5D5;
 @include gradient-vertical(#fafafa, #e9e9e9);
 width:100%;
  height: 34px;
  padding: 0;
  line-height: 40px;
  .widget-icon {
    display: block;
    width: 30px;
    height: 32px;
    position: relative;
    float: left;
    font-size: 111%;
    line-height: 32px;
    text-align: center;
    margin-right: -10px;
  }
}

.widget-name {
  letter-spacing: -1px;
  font-size: 22px;
  margin: 20px 0;
  line-height: normal;
}

.widget-title .widget-name {
  height: 100%;

  white-space: nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  display: inline-block;
  font-size: 14px;
  margin: 0;
  padding: 0 4px 0 8px;
  line-height: 34px;
  font-weight: 400;
  letter-spacing: 0;
  float: left;
}



.widget-title-well {
  min-height      : 20px;
  margin-bottom   : 10px;
  background-color: #f5f5f5;
  border          : 1px solid #e3e3e3;
  box-shadow      : inset 0 1px 1px rgba(0,0,0,.05);
  padding         : 3px;
  border-radius   : 0px;
}


.widget-options {
  width: auto;
  float: right;
  padding: 0;
  margin: 0;
  .button-icon {
    min-width: 33px;
    height: 33px;
    float: left;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    border-left: 1px solid rgba(0, 0, 0, 0.09);
  }
}

/*.widget-options .button-icon:hover { background: #dbdbdb; }*/

.btn, a:link, button {
  -webkit-tap-highlight-color: rgba(169, 3, 41, 0.5);
}

.widget-options .button-icon {
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  line-height: 32px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  border-radius:0;
  background:none;

}

.widget-loader {
  width: 32px;
  height: 32px;
  margin: 0;
  float: right;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  line-height: 32px;
  font-size: 111%;
}



.gridster-mobile .gridster-item {
  height : 300px;
  position: relative;
}



.widget-content {
  color: #333;
  float: left;
  width: 100%;
  position: absolute;
  top: 34px;
  bottom: 0;
  font-size: 13px;
  -khtml-border-radius: 0;
  border-radius: 0;
  margin: 0;

  overflow-y: hidden;
  overflow-x: hidden;
  background: white;
  .table-striped tbody > tr {
    &:nth-child(odd) > {
      td, th {
        background-color: rgba(249, 249, 249, 0.25);
      }
    }
    &:hover > td {
      background-color: rgba(245, 245, 245, 0.75);
    }
  }

  & > .actual-content {
     @include opacity(0);
     @include transition(opacity .10s ease-in-out .10s);
  }

  & > .loading {
     @include opacity(1);
     @include transition(opacity .10s ease-in-out .10s);

  }


  &.loaded > .actual-content {
     @include opacity(1);
     @include transition(opacity .10s ease-in-out .10s);
  }

  &.loaded > .loading > * { //all children because IE is stupid and doesn't work right
    @include opacity(0);
    @include transition(opacity .10s ease-in-out .10s);
  }

}


.widget-card-template {
  & .gridster-item-resizable-handler.handle-se, .gridster-item-resizable-handler.handle-sw{
        bottom: 9px;
  }

  & .widget-card-loader,  .widget-card {
      position: absolute;
      @include opacity(0);
      top: 0;
      left: -8px;
      right: -8px;
      bottom: 0;
      overflow-y: hidden;
      overflow-x: hidden;
      @include transition(opacity .3s ease-in-out);
  }

  & .widget-card-container {
     @include opacity(0);
  }

  & .widget-card-content {
     overflow-y: auto;
     overflow-x: auto;
     position: relative;

     &.no-padding {
      padding: 0 16px;
     }
     // margin-bottom: 20px;
  }

  md-card md-card-footer {
    padding: 0;
  }

  &.loaded .widget-card-container, &.loaded .widget-card, & .widget-card-loader {
     @include opacity(1);
  }

  &.loaded .widget-card-loader {
    @include opacity(0);
  }
}

.widget-handle {
  cursor : move;
}

.widgetBody {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  &.withFooter {
    bottom: 40px;
  }
}

.widgetFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background: rgba(250, 250, 250, 0.85);
  padding: 6px 0;
  /*border-top: 1px solid #ccc;*/
  .form-control {
    height: 28px;
  }
}

.widget-title .widget-options .button-icon:hover {
  background-color: rgba(64, 64, 64, 0.25);
}

.widgetColoredIcon {
  color: rgb(64, 64, 64);
}

// .widget-title.actionView {
//   background: rgb(64, 64, 64);
//   border-color: rgb(64, 64, 64) !important;
// }

.optionsMenu, .rightClickMenu {
  position: absolute;
  z-index: 8000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}

.axisLabels {
  font-size: 12px;
}

.optionsMenu {
  top: -2px;
  right: 20px;
  &.seen {
    display: block;
  }
}

.rightClickMenu.seen {
  display: block;
}

.optionsMenu > li > a, .rightClickMenu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333333;
  white-space: nowrap;
  cursor: pointer;
}

.optionsMenu > li > a {
  &:hover, &:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #428bca;
  }
}

.rightClickMenu > li > a {
  &:hover, &:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #428bca;
  }
  &:hover i.bg, &:focus i.bg {
    color: #333333;
  }
}




#flipContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  perspective: 1000;
  backface-visibility: hidden;
  figure {
        margin:0;
        display            : block;
        position           : absolute;
        width              : 100%;
        height             : 100%;
        /*padding          : 13px 13px;*/
       // perspective        : 800px;
        @include transform-style(preserve-3d);
        backface-visibility: hidden;
        transition-duration: 0.75s;
  }
}

figure.settingsMode {
  @include transform(rotateY(-180deg));
}

.widget {
  &[data-state="drag"], &[data-state="move"] {
    opacity: .5;
  }
  &[data-state="drag"] {
    z-index: 99999;
  }
  &[data-state="move"] {
    z-index: 1500;
  }
  .panel-heading {
    span.option {
      display: none;
    }
    &:hover span.option {
      display: inline-block;
    }
  }
}

span.option {
  color: #888;
  &:hover {
    color: #222;
    cursor: pointer;
  }
}



.widget-xs-1, .widget-xs-2, .widget-xs-3, .widget-xs-4, .widget-xs-5, .widget-xs-6, .widget-xs-7, .widget-xs-8, .widget-xs-9, .widget-xs-10, .widget-xs-11, .widget-xs-12 {
  float: left;
}
.widget-xs-12 {
  width: 100%;
}
.widget-xs-11 {
  width: 91.66666667%;
}
.widget-xs-10 {
  width: 83.33333333%;
}
.widget-xs-9 {
  width: 75%;
}
.widget-xs-8 {
  width: 66.66666667%;
}
.widget-xs-7 {
  width: 58.33333333%;
}
.widget-xs-6 {
  width: 50%;
}
.widget-xs-5 {
  width: 41.66666667%;
}
.widget-xs-4 {
  width: 33.33333333%;
}
.widget-xs-3 {
  width: 25%;
}
.widget-xs-2 {
  width: 16.66666667%;
}
.widget-xs-1 {
  width: 8.33333333%;
}
.widget-xs-offset-12 {
  margin-left: 100%;
}
.widget-xs-offset-11 {
  margin-left: 91.66666667%;
}
.widget-xs-offset-10 {
  margin-left: 83.33333333%;
}
.widget-xs-offset-9 {
  margin-left: 75%;
}
.widget-xs-offset-8 {
  margin-left: 66.66666667%;
}
.widget-xs-offset-7 {
  margin-left: 58.33333333%;
}
.widget-xs-offset-6 {
  margin-left: 50%;
}
.widget-xs-offset-5 {
  margin-left: 41.66666667%;
}
.widget-xs-offset-4 {
  margin-left: 33.33333333%;
}
.widget-xs-offset-3 {
  margin-left: 25%;
}
.widget-xs-offset-2 {
  margin-left: 16.66666667%;
}
.widget-xs-offset-1 {
  margin-left: 8.33333333%;
}
.widget-xs-offset-0 {
  margin-left: 0;
}


.widget-xs-1, .widget-sm-1, .widget-md-1, .widget-lg-1, .widget-xs-2, .widget-sm-2, .widget-md-2, .widget-lg-2, .widget-xs-3, .widget-sm-3, .widget-md-3, .widget-lg-3, .widget-xs-4, .widget-sm-4, .widget-md-4, .widget-lg-4, .widget-xs-5, .widget-sm-5, .widget-md-5, .widget-lg-5, .widget-xs-6, .widget-sm-6, .widget-md-6, .widget-lg-6, .widget-xs-7, .widget-sm-7, .widget-md-7, .widget-lg-7, .widget-xs-8, .widget-sm-8, .widget-md-8, .widget-lg-8, .widget-xs-9, .widget-sm-9, .widget-md-9, .widget-lg-9, .widget-xs-10, .widget-sm-10, .widget-md-10, .widget-lg-10, .widget-xs-11, .widget-sm-11, .widget-md-11, .widget-lg-11, .widget-xs-12, .widget-sm-12, .widget-md-12, .widget-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

/*Poly Fill Spit*/

  .widget-card-template[min-width~="600px"] .widget-sm-1, .widget-card-template[min-width~="600px"] .widget-sm-2, .widget-card-template[min-width~="600px"] .widget-sm-3, .widget-card-template[min-width~="600px"] .widget-sm-4, .widget-card-template[min-width~="600px"] .widget-sm-5, .widget-card-template[min-width~="600px"] .widget-sm-6, .widget-card-template[min-width~="600px"] .widget-sm-7, .widget-card-template[min-width~="600px"] .widget-sm-8, .widget-card-template[min-width~="600px"] .widget-sm-9, .widget-card-template[min-width~="600px"] .widget-sm-10, .widget-card-template[min-width~="600px"] .widget-sm-11, .widget-card-template[min-width~="600px"] .widget-sm-12 {
    float: left;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-12 {
    width: 100%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-11 {
    width: 91.66666667%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-10 {
    width: 83.33333333%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-9 {
    width: 75%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-8 {
    width: 66.66666667%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-7 {
    width: 58.33333333%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-6 {
    width: 50%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-5 {
    width: 41.66666667%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-4 {
    width: 33.33333333%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-3 {
    width: 25%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-2 {
    width: 16.66666667%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-1 {
    width: 8.33333333%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-offset-12 {
    margin-left: 100%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-offset-9 {
    margin-left: 75%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-offset-6 {
    margin-left: 50%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-offset-3 {
    margin-left: 25%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .widget-card-template[min-width~="600px"] .widget-sm-offset-0 {
    margin-left: 0;
  }



  .widget-card-template[min-width~="900"] .widget-md-1, .widget-card-template[min-width~="900"] .widget-md-2, .widget-card-template[min-width~="900"] .widget-md-3, .widget-card-template[min-width~="900"] .widget-md-4, .widget-card-template[min-width~="900"] .widget-md-5, .widget-card-template[min-width~="900"] .widget-md-6, .widget-card-template[min-width~="900"] .widget-md-7, .widget-card-template[min-width~="900"] .widget-md-8, .widget-card-template[min-width~="900"] .widget-md-9, .widget-card-template[min-width~="900"] .widget-md-10, .widget-card-template[min-width~="900"] .widget-md-11, .widget-card-template[min-width~="900"] .widget-md-12 {
    float: left;
  }
  .widget-card-template[min-width~="900"] .widget-md-12 {
    width: 100%;
  }
  .widget-card-template[min-width~="900"] .widget-md-11 {
    width: 91.66666667%;
  }
  .widget-card-template[min-width~="900"] .widget-md-10 {
    width: 83.33333333%;
  }
  .widget-card-template[min-width~="900"] .widget-md-9 {
    width: 75%;
  }
  .widget-card-template[min-width~="900"] .widget-md-8 {
    width: 66.66666667%;
  }
  .widget-card-template[min-width~="900"] .widget-md-7 {
    width: 58.33333333%;
  }
  .widget-card-template[min-width~="900"] .widget-md-6 {
    width: 50%;
  }
  .widget-card-template[min-width~="900"] .widget-md-5 {
    width: 41.66666667%;
  }
  .widget-card-template[min-width~="900"] .widget-md-4 {
    width: 33.33333333%;
  }
  .widget-card-template[min-width~="900"] .widget-md-3 {
    width: 25%;
  }
  .widget-card-template[min-width~="900"] .widget-md-2 {
    width: 16.66666667%;
  }
  .widget-card-template[min-width~="900"] .widget-md-1 {
    width: 8.33333333%;
  }
  .widget-card-template[min-width~="900"] .widget-md-offset-12 {
    margin-left: 100%;
  }
  .widget-card-template[min-width~="900"] .widget-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .widget-card-template[min-width~="900"] .widget-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .widget-card-template[min-width~="900"] .widget-md-offset-9 {
    margin-left: 75%;
  }
  .widget-card-template[min-width~="900"] .widget-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .widget-card-template[min-width~="900"] .widget-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .widget-card-template[min-width~="900"] .widget-md-offset-6 {
    margin-left: 50%;
  }
  .widget-card-template[min-width~="900"] .widget-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .widget-card-template[min-width~="900"] .widget-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .widget-card-template[min-width~="900"] .widget-md-offset-3 {
    margin-left: 25%;
  }
  .widget-card-template[min-width~="900"] .widget-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .widget-card-template[min-width~="900"] .widget-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .widget-card-template[min-width~="900"] .widget-md-offset-0 {
    margin-left: 0;
  }



  .widget-card-template[min-width~="1200px"] .widget-lg-1, .widget-card-template[min-width~="1200px"] .widget-lg-2, .widget-card-template[min-width~="1200px"] .widget-lg-3, .widget-card-template[min-width~="1200px"] .widget-lg-4, .widget-card-template[min-width~="1200px"] .widget-lg-5, .widget-card-template[min-width~="1200px"] .widget-lg-6, .widget-card-template[min-width~="1200px"] .widget-lg-7, .widget-card-template[min-width~="1200px"] .widget-lg-8, .widget-card-template[min-width~="1200px"] .widget-lg-9, .widget-card-template[min-width~="1200px"] .widget-lg-10, .widget-card-template[min-width~="1200px"] .widget-lg-11, .widget-card-template[min-width~="1200px"] .widget-lg-12 {
    float: left;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-12 {
    width: 100%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-11 {
    width: 91.66666667%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-10 {
    width: 83.33333333%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-9 {
    width: 75%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-8 {
    width: 66.66666667%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-7 {
    width: 58.33333333%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-6 {
    width: 50%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-5 {
    width: 41.66666667%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-4 {
    width: 33.33333333%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-3 {
    width: 25%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-2 {
    width: 16.66666667%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-1 {
    width: 8.33333333%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-offset-12 {
    margin-left: 100%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-offset-9 {
    margin-left: 75%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-offset-6 {
    margin-left: 50%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-offset-3 {
    margin-left: 25%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .widget-card-template[min-width~="1200px"] .widget-lg-offset-0 {
    margin-left: 0;
  }


/*Hidden*/


.widget-card-template .widget-hidden-micro {
    display: none !important;
}
.widget-card-template[min-width~="300px"] .widget-hidden-micro {
    display: flex !important;
}

.widget-card-template .widget-hidden-xs {
    display: none !important;
}
.widget-card-template[min-width~="600px"] .widget-hidden-xs {
    display: flex !important;
}

.widget-card-template .widget-hidden-xs-short {
    display: none !important;
}
.widget-card-template[min-height~="400px"] .widget-hidden-xs-short {
    display: flex !important;
}
.widget-card-template[min-width~="600px"] .widget-hidden-xs-short {
    display: flex !important;
}

.widget-card-template .widget-hidden-sm {
    display: flex !important;
}
.widget-card-template[min-width~="600px"] .widget-hidden-sm {
    display: none !important;
}
.widget-card-template[min-width~="900"] .widget-hidden-sm {
    display: flex !important;
}

.widget-card-template .widget-hidden-md {
    display: flex !important;
}
.widget-card-template[min-width~="900"] .widget-hidden-md {
    display: none !important;
}
.widget-card-template[min-width~="1200px"] .widget-hidden-md {
    display: flex !important;
}

.widget-card-template .widget-hidden-lg {
    display: flex !important;
}
.widget-card-template[min-width~="1200px"] .widget-hidden-lg {
    display: none !important;
}




/*Visible*/


.widget-card-template .widget-visible-micro {
    display: flex !important;
}
.widget-card-template[min-width~="300px"] .widget-visible-micro {
    display: none !important;
}

.widget-card-template .widget-visible-xs {
    display: flex !important;
}
.widget-card-template[min-width~="600px"] .widget-visible-xs {
    display: none !important;
}

.widget-card-template .widget-visible-sm {
    display: none !important;
}
.widget-card-template[min-width~="600px"] .widget-visible-sm {
    display: flex !important;
}
.widget-card-template[min-width~="900"] .widget-visible-sm {
    display: none !important;
}

.widget-card-template .widget-visible-md {
    display: none !important;
}
.widget-card-template[min-width~="900"] .widget-visible-md {
    display: flex !important;
}
.widget-card-template[min-width~="1200px"] .widget-visible-md {
    display: none !important;
}

.widget-card-template .widget-visible-lg {
    display: none !important;
}
.widget-card-template[min-width~="1200px"] .widget-visible-lg {
    display: flex !important;
}

.widget[min-width~="600px"] .widget-sm-1, .widget[min-width~="600px"] .widget-sm-2, .widget[min-width~="600px"] .widget-sm-3, .widget[min-width~="600px"] .widget-sm-4, .widget[min-width~="600px"] .widget-sm-5, .widget[min-width~="600px"] .widget-sm-6, .widget[min-width~="600px"] .widget-sm-7, .widget[min-width~="600px"] .widget-sm-8, .widget[min-width~="600px"] .widget-sm-9, .widget[min-width~="600px"] .widget-sm-10, .widget[min-width~="600px"] .widget-sm-11, .widget[min-width~="600px"] .widget-sm-12 {
    float: left;
  }
  .widget[min-width~="600px"] .widget-sm-12 {
    width: 100%;
  }
  .widget[min-width~="600px"] .widget-sm-11 {
    width: 91.66666667%;
  }
  .widget[min-width~="600px"] .widget-sm-10 {
    width: 83.33333333%;
  }
  .widget[min-width~="600px"] .widget-sm-9 {
    width: 75%;
  }
  .widget[min-width~="600px"] .widget-sm-8 {
    width: 66.66666667%;
  }
  .widget[min-width~="600px"] .widget-sm-7 {
    width: 58.33333333%;
  }
  .widget[min-width~="600px"] .widget-sm-6 {
    width: 50%;
  }
  .widget[min-width~="600px"] .widget-sm-5 {
    width: 41.66666667%;
  }
  .widget[min-width~="600px"] .widget-sm-4 {
    width: 33.33333333%;
  }
  .widget[min-width~="600px"] .widget-sm-3 {
    width: 25%;
  }
  .widget[min-width~="600px"] .widget-sm-2 {
    width: 16.66666667%;
  }
  .widget[min-width~="600px"] .widget-sm-1 {
    width: 8.33333333%;
  }
  .widget[min-width~="600px"] .widget-sm-offset-12 {
    margin-left: 100%;
  }
  .widget[min-width~="600px"] .widget-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .widget[min-width~="600px"] .widget-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .widget[min-width~="600px"] .widget-sm-offset-9 {
    margin-left: 75%;
  }
  .widget[min-width~="600px"] .widget-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .widget[min-width~="600px"] .widget-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .widget[min-width~="600px"] .widget-sm-offset-6 {
    margin-left: 50%;
  }
  .widget[min-width~="600px"] .widget-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .widget[min-width~="600px"] .widget-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .widget[min-width~="600px"] .widget-sm-offset-3 {
    margin-left: 25%;
  }
  .widget[min-width~="600px"] .widget-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .widget[min-width~="600px"] .widget-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .widget[min-width~="600px"] .widget-sm-offset-0 {
    margin-left: 0;
  }



  .widget[min-width~="900"] .widget-md-1, .widget[min-width~="900"] .widget-md-2, .widget[min-width~="900"] .widget-md-3, .widget[min-width~="900"] .widget-md-4, .widget[min-width~="900"] .widget-md-5, .widget[min-width~="900"] .widget-md-6, .widget[min-width~="900"] .widget-md-7, .widget[min-width~="900"] .widget-md-8, .widget[min-width~="900"] .widget-md-9, .widget[min-width~="900"] .widget-md-10, .widget[min-width~="900"] .widget-md-11, .widget[min-width~="900"] .widget-md-12 {
    float: left;
  }
  .widget[min-width~="900"] .widget-md-12 {
    width: 100%;
  }
  .widget[min-width~="900"] .widget-md-11 {
    width: 91.66666667%;
  }
  .widget[min-width~="900"] .widget-md-10 {
    width: 83.33333333%;
  }
  .widget[min-width~="900"] .widget-md-9 {
    width: 75%;
  }
  .widget[min-width~="900"] .widget-md-8 {
    width: 66.66666667%;
  }
  .widget[min-width~="900"] .widget-md-7 {
    width: 58.33333333%;
  }
  .widget[min-width~="900"] .widget-md-6 {
    width: 50%;
  }
  .widget[min-width~="900"] .widget-md-5 {
    width: 41.66666667%;
  }
  .widget[min-width~="900"] .widget-md-4 {
    width: 33.33333333%;
  }
  .widget[min-width~="900"] .widget-md-3 {
    width: 25%;
  }
  .widget[min-width~="900"] .widget-md-2 {
    width: 16.66666667%;
  }
  .widget[min-width~="900"] .widget-md-1 {
    width: 8.33333333%;
  }
  .widget[min-width~="900"] .widget-md-offset-12 {
    margin-left: 100%;
  }
  .widget[min-width~="900"] .widget-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .widget[min-width~="900"] .widget-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .widget[min-width~="900"] .widget-md-offset-9 {
    margin-left: 75%;
  }
  .widget[min-width~="900"] .widget-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .widget[min-width~="900"] .widget-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .widget[min-width~="900"] .widget-md-offset-6 {
    margin-left: 50%;
  }
  .widget[min-width~="900"] .widget-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .widget[min-width~="900"] .widget-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .widget[min-width~="900"] .widget-md-offset-3 {
    margin-left: 25%;
  }
  .widget[min-width~="900"] .widget-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .widget[min-width~="900"] .widget-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .widget[min-width~="900"] .widget-md-offset-0 {
    margin-left: 0;
  }



  .widget[min-width~="1200px"] .widget-lg-1, .widget[min-width~="1200px"] .widget-lg-2, .widget[min-width~="1200px"] .widget-lg-3, .widget[min-width~="1200px"] .widget-lg-4, .widget[min-width~="1200px"] .widget-lg-5, .widget[min-width~="1200px"] .widget-lg-6, .widget[min-width~="1200px"] .widget-lg-7, .widget[min-width~="1200px"] .widget-lg-8, .widget[min-width~="1200px"] .widget-lg-9, .widget[min-width~="1200px"] .widget-lg-10, .widget[min-width~="1200px"] .widget-lg-11, .widget[min-width~="1200px"] .widget-lg-12 {
    float: left;
  }
  .widget[min-width~="1200px"] .widget-lg-12 {
    width: 100%;
  }
  .widget[min-width~="1200px"] .widget-lg-11 {
    width: 91.66666667%;
  }
  .widget[min-width~="1200px"] .widget-lg-10 {
    width: 83.33333333%;
  }
  .widget[min-width~="1200px"] .widget-lg-9 {
    width: 75%;
  }
  .widget[min-width~="1200px"] .widget-lg-8 {
    width: 66.66666667%;
  }
  .widget[min-width~="1200px"] .widget-lg-7 {
    width: 58.33333333%;
  }
  .widget[min-width~="1200px"] .widget-lg-6 {
    width: 50%;
  }
  .widget[min-width~="1200px"] .widget-lg-5 {
    width: 41.66666667%;
  }
  .widget[min-width~="1200px"] .widget-lg-4 {
    width: 33.33333333%;
  }
  .widget[min-width~="1200px"] .widget-lg-3 {
    width: 25%;
  }
  .widget[min-width~="1200px"] .widget-lg-2 {
    width: 16.66666667%;
  }
  .widget[min-width~="1200px"] .widget-lg-1 {
    width: 8.33333333%;
  }
  .widget[min-width~="1200px"] .widget-lg-offset-12 {
    margin-left: 100%;
  }
  .widget[min-width~="1200px"] .widget-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .widget[min-width~="1200px"] .widget-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .widget[min-width~="1200px"] .widget-lg-offset-9 {
    margin-left: 75%;
  }
  .widget[min-width~="1200px"] .widget-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .widget[min-width~="1200px"] .widget-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .widget[min-width~="1200px"] .widget-lg-offset-6 {
    margin-left: 50%;
  }
  .widget[min-width~="1200px"] .widget-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .widget[min-width~="1200px"] .widget-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .widget[min-width~="1200px"] .widget-lg-offset-3 {
    margin-left: 25%;
  }
  .widget[min-width~="1200px"] .widget-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .widget[min-width~="1200px"] .widget-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .widget[min-width~="1200px"] .widget-lg-offset-0 {
    margin-left: 0;
  }


/*Hidden*/


.widget .widget-hidden-micro {
    display: none !important;
}
.widget[min-width~="300px"] .widget-hidden-micro {
    display: block !important;
}

.widget .widget-hidden-xs {
    display: none !important;
}
.widget[min-width~="600px"] .widget-hidden-xs {
    display: block !important;
}

.widget .widget-hidden-xs-short {
    display: none !important;
}
.widget[min-height~="400px"] .widget-hidden-xs-short {
    display: block !important;
}
.widget[min-width~="600px"] .widget-hidden-xs-short {
    display: block !important;
}

.widget .widget-hidden-sm {
    display: block !important;
}
.widget[min-width~="600px"] .widget-hidden-sm {
    display: none !important;
}
.widget[min-width~="900"] .widget-hidden-sm {
    display: block !important;
}

.widget .widget-hidden-md {
    display: block !important;
}
.widget[min-width~="900"] .widget-hidden-md {
    display: none !important;
}
.widget[min-width~="1200px"] .widget-hidden-md {
    display: block !important;
}

.widget .widget-hidden-lg {
    display: block !important;
}
.widget[min-width~="1200px"] .widget-hidden-lg {
    display: none !important;
}




/*Visible*/


.widget .widget-visible-micro {
    display: block !important;
}
.widget[min-width~="300px"] .widget-visible-micro {
    display: none !important;
}

.widget .widget-visible-xs {
    display: block !important;
}
.widget[min-width~="600px"] .widget-visible-xs {
    display: none !important;
}

.widget .widget-visible-sm {
    display: none !important;
}
.widget[min-width~="600px"] .widget-visible-sm {
    display: block !important;
}
.widget[min-width~="900"] .widget-visible-sm {
    display: none !important;
}

.widget .widget-visible-md {
    display: none !important;
}
.widget[min-width~="900"] .widget-visible-md {
    display: block !important;
}
.widget[min-width~="1200px"] .widget-visible-md {
    display: none !important;
}

.widget .widget-visible-lg {
    display: none !important;
}
.widget[min-width~="1200px"] .widget-visible-lg {
    display: block !important;
}






/* Weather Forecast */
.weather-forecast md-list-item .md-avatar, md-list-item .md-list-item-inner .md-avatar {
    width: 64px;
    height: 64px;
}
