
html, body {
  color: rgb(67, 73, 77);
  // text-shadow: 0 0 3px rgba(255, 255, 255, 0.4);
  // overflow: hidden;
  width: 100%;
  height: 100%;
  // font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
  // font-family: 'Roboto', sans-serif;
  // background-image: url(../img/sunny_bg.jpg);
  background-size: 100% 100%;
  background-attachment: fixed;
  background-color:#eee;
}
.table-fix-head {
  overflow-y: auto; 
  height: 500px; 
}
.table-fix-head-dashboard {
  overflow-y: auto;  
}
.table-fix-head thead th, .table-fix-head-dashboard thead th {
  position: sticky;
  top: -1px; /* table head will be placed from the top of the table and sticks to it */
  background: #eee;
  border: 1px solid #ccc;
}
.gains-to-budget-totals{
    font-weight: bold;
  line-height: 80%;
}

.spes-gains-graph-detail-node-label {
    text-align: left;
    font-size: 22px;
}

#rotate-commercial {
    -moz-transform: rotate(-90.0deg);
    -o-transform: rotate(-90.0deg);
    -webkit-transform: rotate(-90.0deg);
    transform: rotate(-90.0deg);
    height: 135px;
    font-weight: 900;
    background-color: #ff0000;
}

#rotate-residential {
    -moz-transform: rotate(-90.0deg);
    -o-transform: rotate(-90.0deg);
    -webkit-transform: rotate(-90.0deg);
    transform: rotate(-90.0deg);
    height: 135px;
    font-weight: 900;
    background-color: #ff0000;
}

.k-tooltip {
    width: auto;
    font-weight: 500;
    font-size: 15px;
    margin-left: 10px;
    padding: 5px;
}

.k-chart {
    max-height: 100%;
}

md-backdrop.md-select-backdrop {
    z-index:999998;
}

.md-select-menu-container {
    z-index:999999;
}

.md-select-value > span:not(.md-select-icon) {
    max-width: 400px;
}

md-optgroup label {
    cursor: pointer;
    font-size: 17px;
}

.multiSelectGroup {
    text-transform: uppercase;
}

//md-input-container label:not(.md-no-float):not(.md-container-ignore) {
//    font-size: 20px;
//    overflow: unset;
//}
//
//md-input-container label:not(.md-no-float):not(.md-container-ignore) .date-placeholder {
//    font-size: 12px;
//}
//
//md-select .md-select-value.md-select-placeholder {
//    //color: rgba(0, 0, 0, 0.38);
//    font-size: 20px;
//    font-weight: 600;
//}
//
//md-input-container label {
//    color: #000000;
//}
//
//md-select .md-select-value.md-select-placeholder {
//    color: #000000;
//}
//
//.md-select-value > span:not(.md-select-icon) .md-text {
//    color: #000000;
//    font-weight: bold;
//}
//
//.md-datepicker-input {
//    font-size: 16px;
//    font-weight: bold;
//}
//
//md-input-container.md-input-focused:not(.md-input-has-value) md-select .md-select-value {
//    font-size: 15px;
//    //font-weight: 500;
//}
//
//.md-container-ignore {
//    font-size: 18px;
//}
//
//md-input-container:not(.md-input-valid).md-input-focused label {
//    color: #177bbb;
//    font-size: 20px;
//}
//
//md-input-container:not(.md-input-valid).md-input-has-value label {
//    font-size: 22px;
//}

input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
}

.widget-name {
    text-transform: capitalize;
}

#title {
    font-size: 20px;
    text-transform: capitalize;
}

.zip-code-container ul {
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
}

.zip-code-container ul li {
    //border: 1px solid #000;
    border-radius: 3px;
    display: inline-block;
    padding: 5px 10px;
    margin: 0 5px 5px 0;
    font-weight: 500;
}

md-toast {
    z-index: 10000000 !important;
    position: absolute !important;
}

.panel-heading h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
    width: 75%;

}

.mb-0 {
    margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0;
}

.mt-5 {
  margin-top: 5px;
}

.pt-6{
    padding-top: 6px;
}

.mr-6 {
  margin-right: 5px;
}

.ml-6 {
  margin-right: 5px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

md-radio-button.green-colored .md-on {
    background-color: #179877;
}

md-radio-button.md-checked.green-colored .md-off {
    border-color: #179877;
}

md-radio-button.md-checked.green-colored.md-ink-ripple .md-off {
    color: #179877;
}

md-checkbox {
    font-weight: 600;
}

md-checkbox.md-checked.green-colored .md-icon {
    background-color: #179877;
}

md-checkbox.green-colored span {
    font-weight: 700;
}


md-checkbox.md-checked.blue-colored .md-icon {
    background-color: #177bbb;
}

.use-accounting-date-info-text {
    text-indent: 15px;
    margin-top: -15px;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus{
                                border-top: 2px #177bbb solid;
                                font-weight: bold;
                            }

md-tooltip span {
    font-size: 18px;
}

treecontrol.tree-light li .tree-unselectable {
    color: #999;
}

treecontrol.tree-light li .tree-selected {
    background-color: #cccccc;
}

.clt, .clt ul, .clt li {
   position: relative;
   padding : 0px;
}

.clt ul {
    list-style: none;
    padding-left: 32px;
}

.clt li::before, .clt li::after {
    content: "";
    position: absolute;
    left: -12px;
}

.clt li::before {
    border-top: 1px solid #8B8D8D;
    top: 9px;
    width: 8px;
    height: 0;
}

.clt li::after {
    border-left: 1px solid #8B8D8D;
    height: 100%;
    width: 0px;
    top: 2px;
}
.clt ul > li:last-child::after {
    height: 8px;
}
treecontrol{
    display: block;
    padding-left:20px;
}


.ag-fresh .ag-root {
  border: 1px solid #808080;
  font-family: 'Roboto', sans-serif;
}

.ag-fresh .ag-visible-icons {
  cursor : pointer;
}


.fa-half {
  font-size: 1.5em;
}

.no-margins {
    margin: 0 !important;
}

.nav, .pagination, .carousel, .panel-title a {
  cursor: pointer;
}
.img-responsive {
  margin: 0 auto;
}

.popover {
  max-width: 500px;
  min-width: 500px;
  z-index: 99999;
}

.popover-content {
  max-height: 400px;
  overflow-y: auto;
}

.introjs-helperLayer {
  background: none;
  border: 1px solid red; //rgba(255, 255, 255, 0.5);
}

.acctGainLossPie {
  height : 200px; padding-top: 45px;
}

.centerVertically {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.spinner-container{
  display: inline-block;
}

.ui-view-container {
  overflow: hidden;
}

[data-ui-view].ng-enter{
  @include fadeIn();
}

[ng-grid=gridOptions1] .ngTopPanel {
    z-index: 2;
}


.ibox-title {
  color        : inherit;
  margin-bottom: 0;
  padding      : 0px 10px 10px;
  min-height   : 48px;
}

.close {
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20);
  text-decoration: none;
}
.close:hover {
  color: #000000;
  text-decoration: none;
  opacity: 0.4;
  filter: alpha(opacity=40);
  cursor: pointer;
}

/******************
MULTI SELECT
******/
/*
 * Don't modify things marked with ! - unless you know what you're doing
 */

/* ! vertical layout */
.multiSelect .vertical {
    float: none;
}

/* ! horizontal layout */
.multiSelect .horizontal:not(.multiSelectGroup) {
    float: left;
}

/* ! create a "row" */
.multiSelect .line {
    padding:  2px 0px 4px 0px;
    max-height: 30px;
    overflow: hidden;
    box-sizing: content-box;
}

/* ! create a "column" */
.multiSelect .acol {
    display: inline-block;
    min-width: 12px;
}

/* ! */
.multiSelect .inlineBlock {
    display: inline-block;
}

/* the multiselect button */
.multiSelect > button {
    width:100%;
    display: inline-block;
    position: relative;
    text-align: left;
    cursor: pointer;
    border: 1px solid #c6c6c6;
    padding: 1px 8px 1px 8px;
    // font-size: 14px;
    min-height : 32px !important;
    border-radius: 4px;
    color: #555;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    white-space:normal;
    background-color: #fff;
    background-image: linear-gradient(#fff, #f7f7f7);
}

/* button: hover */
.multiSelect > button:hover {
    background-image: linear-gradient(#fff, #e9e9e9);
}

/* button: disabled */
.multiSelect > button:disabled {
    background-image: linear-gradient(#fff, #fff);
    border: 1px solid #ddd;
    color: #999;
}

/* button: clicked */
.multiSelect .buttonClicked {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset, 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* labels on the button */
.multiSelect .buttonLabel {
    display: inline-block;
    // padding: 5px 0px 5px 0px;
}

/* downward pointing arrow */
.multiSelect .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin: 0px 0px 1px 12px  !important;
    vertical-align: middle;
    border-top: 4px solid #333;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    border-bottom: 0 dotted;
}

/* the main checkboxes and helper layer */
.multiSelect .checkboxLayer {
    background-color: #fff;
    // position: absolute;
    position: fixed;
    z-index: 999;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    min-width:278px;
    display: none !important;
}

/* container of helper elements */
.multiSelect .helperContainer {
    border-bottom: 1px solid #ddd;
    padding: 8px 8px 0px 8px;
}

/* helper buttons (select all, none, reset); */
.multiSelect .helperButton {
    display: inline;
    text-align: center;
    cursor: pointer;
    border: 1px solid #ccc;
    height: 26px;
    font-size: 13px;
    border-radius: 2px;
    color: #666;
    background-color: #f1f1f1;
    line-height: 1.6;
    margin: 0px 0px 8px 0px;
}

.multiSelect .helperButton.reset{
    float: right;
}

.multiSelect .helperButton:not( .reset ) {
    margin-right: 4px;
}

/* clear button */
.multiSelect .clearButton {
    position: absolute;
    display: inline;
    text-align: center;
    cursor: pointer;
    border: 1px solid #ccc;
    height: 22px;
    width: 22px;
    font-size: 13px;
    border-radius: 2px;
    color: #666;
    background-color: #f1f1f1;
    line-height: 1.4;
    right : 2px;
    top: 4px;
}

/* filter */
.multiSelect .inputFilter {
    border-radius: 2px;
    border: 1px solid #ccc;
    height: 26px;
    font-size: 14px;
    width:100%;
    padding-left:7px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    color: #888;
    margin: 0px 0px 8px 0px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

/* helper elements on hover & focus */
.multiSelect .clearButton:hover,
.multiSelect .helperButton:hover {
    border: 1px solid #ccc;
    color: #999;
    background-color: #f4f4f4;
}
.multiSelect .helperButton:disabled {
    color: #ccc;
    border: 1px solid #ddd;
}

.multiSelect .clearButton:focus,
.multiSelect .helperButton:focus,
.multiSelect .inputFilter:focus {
    border: 1px solid #66AFE9 !important;
    outline: 0;
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,.065), 0 0 5px rgba(102, 175, 233, .6) !important;
            box-shadow: inset 0 0 1px rgba(0,0,0,.065), 0 0 5px rgba(102, 175, 233, .6) !important;
}

/* container of multi select items */
.multiSelect .checkBoxContainer {
    display: block;
    padding: 8px;
    overflow: hidden;
}

/* ! to show / hide the checkbox layer above */
.multiSelect .show {
    display: block !important;
}

/* item labels */
.multiSelect .multiSelectItem {
    display: block;
    padding: 3px;
    color: #444;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    position: relative;
    min-width:278px;
    min-height: 32px;
}

/* Styling on selected items */
.multiSelect .multiSelectItem:not(.multiSelectGroup).selected
{
    background-image: linear-gradient( #e9e9e9, #f1f1f1 );
    color: #555;
    cursor: pointer;
    border-top: 1px solid #e4e4e4;
    border-left: 1px solid #e4e4e4;
    border-right: 1px solid #d9d9d9;
}

.multiSelect .multiSelectItem .acol label {
    display: inline-block;
    padding-right: 30px;
    margin: 0px;
    font-weight: normal;
    line-height: normal;
}

/* item labels focus on mouse hover */
.multiSelect .multiSelectItem:hover,
.multiSelect .multiSelectGroup:hover {
    background-image: linear-gradient( #c1c1c1, #999 ) !important;
    color: #fff !important;
    cursor: pointer;
    border: 1px solid #ccc !important;
}

/* item labels focus using keyboard */
.multiSelect .multiSelectFocus {
    background-image: linear-gradient( #c1c1c1, #999 ) !important;
    color: #fff !important;
    cursor: pointer;
    border: 1px solid #ccc !important;
}

/* change mouse pointer into the pointing finger */
.multiSelect .multiSelectItem span:hover,
.multiSelect .multiSelectGroup span:hover
{
    cursor: pointer;
}

/* ! group labels */
.multiSelect .multiSelectGroup {
    display: block;
    clear: both;
}

/* right-align the tick mark (&#10004;) */
.multiSelect .tickMark {
    display:inline-block;
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 10px;
}

/* hide the original HTML checkbox away */
.multiSelect .checkbox {
    color: #ddd !important;
    position: absolute;
    left: -9999px;
    cursor: pointer;
}

/* checkboxes currently disabled */
.multiSelect .disabled,
.multiSelect .disabled:hover,
.multiSelect .disabled label input:hover ~ span {
    color: #c4c4c4 !important;
    cursor: not-allowed !important;
}

/* If you use images in button / checkbox label, you might want to change the image style here. */
.multiSelect img {
    vertical-align: middle;
    margin-bottom:0px;
    max-height: 22px;
    max-width:22px;
}
/**********/

.ngRow {
  z-index: 0;
}

body.tooltip {
  z-index: 99999;
}

.cellToolTip {
    overflow: visible;
}

.tooltip-inner {
  max-width: 400px;
}


.text-gradient-success-dk {
  color: #117f63;
  @include gradient-directional(#116d7f, #117f63, $deg: 135deg);
  //background-image: -webkit-linear-gradient(135deg, #116d7f, #117f63 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


// a {
//   &, &:hover {
//     text-decoration: none;
//   }
// }

// .thumbnail {
//   background: $brand-white;
// }

// a.thumbnail:hover, a.thumbnail:focus, a.thumbnail.active {
//   border-color: $gray-light;
// }

.gridStyle {
  border: 1px solid rgb(212, 212, 212);
  width: auto;
  height: 800px;
}

::selection {
  background-color: #13A89E;
  color: #ecf0f1;
}

.txt-color-blueDark {
  color: #4c4f53 !important;
}


h1, h2, h3, h4 {
  font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
  font-weight: 300;
}

h1 {
  letter-spacing: -1px;
  font-size: 24px;
  margin: 10px 0;
}

.biggerText {
  font-size: larger;
  font-weight: bold;
}

.textLeft {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.clickable {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.padLeft5 {
  padding-left: 5px;
}

.padRight5 {
  padding-right: 5px;
}

.icon-padding {
  padding: 3px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fullHeight {
  height:100%
}

.fullWidth {
  width: 90%; }

.autoScrollVertical {
  overflow-y : auto;
  overflow-x: hidden;
}

.padTop20 {
  padding-top:20px;
}

.card {
  background : white; box-shadow: 0 2px 4px rgba(0,0,0,0.1); padding-top:10px; padding-bottom:10px;
  border: 1px #E7E7E7 solid;
}

.card .cardContent{
  height:150px;
}

.fixed {
  position:fixed;
  z-index : 9999;
}

.topRight {
  top:0;
  right:0;
}
.marginRight25 {
  margin-right:25px;
}
.marginRight10 {
  margin-right:10px;
}





.margin-bottom {
  margin-bottom: 15px;
}

.margin-top {
  margin-top: 15px;
}

.table-responsive {
  overflow-x: auto;
  height: 100%;
}
.table-wrap {
    // table-layout: fixed;
    word-wrap: break-word;
}
.table-wrap > thead > tr > {
  th {
    vertical-align: middle
  }
}
.table-wrap > tbody > tr > {
  th, td {
    white-space: normal !important;
  }
}

.table > thead > tr > th > button.btn.btn-link {
  font-weight: bold !important;
}

.table > thead > tr > th.no-border {
    border-bottom: none;
}

.table > tfoot > tr > td {
    font-weight: bold;
}

.table .clickable:hover {
    color: #898989;
    text-decoration: underline;
}

[data-ui-view=""] {
  height: 100%;
  width: 100%;
}

// .unauthenticatedContainer {
//   position: relative;
  // height: 100%;
  // width: 100%;
  // background-color: rgb(242, 244, 248);
  // color: rgb(67, 73, 77);
//   /*background: url(/Content/img/bg_icons.png), url(/Content/img/bg.png), no-repeat, #fff;*/
//   background: white url(../img/cube-room-700.jpg) no-repeat;
//   .contentWrapper {
//     position: absolute;
//     top: 50%;
//     width: 100%;
//     text-align: center;
//     height: 390px;
//     -webkit-transition: .25s top ease-in-out;
//     -moz-transition: .25s top ease-in-out;
//     -ms-transition: .25s top ease-in-out;
//     -o-transition: .25s top ease-in-out;
//     transition: .25s top ease-in-out;
//     .content {
//       margin: 0 auto;
//       padding: 30px;
//       float: none;
//       width: 400px;
//       height: 100%;
//       /*box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.5);*/
//       background: rgba(25, 25, 25, 0.75);
//       border: 1px solid rgb(25, 25, 25);
//       /*border: 12px double rgb(230, 230, 230);*/
//       position: relative;
//       top: -82%;
//       color: white;
//       -webkit-transition: .25s top ease-in-out;
//       -moz-transition: .25s top ease-in-out;
//       -ms-transition: .25s top ease-in-out;
//       -o-transition: .25s top ease-in-out;
//       transition: .25s top ease-in-out;
//       h6 {
//         text-transform: uppercase;
//         margin: 0 0 15px 0;
//         font-size: 18px;
//       }
//     }
//   }
//   .footer {
//     position: absolute;
//     height: 50px;
//     width: 100%;
//     bottom: 0;
//     background: rgba(25, 25, 25, 0.75);
//     /*background: rgb(4, 76, 38);*/
//     border-top: 1px solid rgb(25, 25, 25);
//     padding: 0 20px;
//     .btn-link {
//       color: white;
//       text-decoration: none;
//     }
//     .footerContainer {
//       float: right;
//     }
//     .btn-link {
//       i {
//         margin: 4px 0;
//       }
//       h4 {
//         margin: 8px 0;
//       }
//     }
//   }
//   .contentWrapper .content .control-label {
//     float: left;
//     font-weight: normal;
//   }
//  }

.even {
  background: #e7e7e7;
}

.bold {
  font-weight: bold;
}

.modal-content {
  color: #333333;
}
.invisible-hr {
    visibility: hidden;
}

// label tag used in integration settings tab(s)
.int-set-label {
	font-weight: normal;
}

.invisible-hr {
    visibility: hidden;
}

.separator {
    display: inline-block;
    margin: 0 4px;
    color: #444;
}

.santa-custom-mtd-ytd-header {
    font-size: 22px;
    font-weight: 400;
    margin-top: -48px;
}

.santa-custom-mtd-ytd-header + hr {
    margin-top: -1px;
}

.santa-custom-budget-font {
    font-size: 28px !important;
}

.santa-custom-budget-font-numbers {
    font-size: 22px !important;
}

.style-dollar-small {
    font-size: 15px;
    vertical-align: super;
}

.style-percent-small {
    font-size: 20px;
    vertical-align: super;
}

.style-period {
    vertical-align: super;
    font-size: 20px;
    margin-left: 2px;
}



@media (max-width: 767px) {
  body {
    min-height: 450px;
  }
      .modal-dialog.form-dialog {
                width: 75%;
                margin-right: auto;
                margin-left: auto;
            }
}




.searchIcon {
  -webkit-transition: top .1s ease-in-out;
  -moz-transition: top .1s ease-in-out;
  -ms-transition: top .1s ease-in-out;
  -o-transition: top .1s ease-in-out;
  transition: top .1s ease-in-out;
}

.progress-sm {
  height: 5px;
  margin: 3px 0;
  width: 80%;
}

.text-warning {
  color: #f0ad4e;
}

.table tbody > tr > td {
  vertical-align: middle;
}

.pagination, .table {
  margin: 0;
}

.noSidePadding {
  padding-left: 0;
  padding-right: 0;
}

.pagination > li > {
  a, span {
    padding: 3px 12px;
  }
}

.widgetFooter .input-group-btn button {
  padding: 3px 12px;
}

.checkbox {
  margin: 5px;
}

hr {
  margin: 15px 0;
}

.table > {
  thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
    border-top: none;
  }
}

.default-table > {
  thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
    border-top: 1px solid #ddd;
  }
}

.commentBorder {
  border-left: 4px solid #4682b4;
  &.even {
    border-left: 4px solid #32cd32;
    border-left: 4px solid #32cd32;
    background: white;
  }
}

.comment {
  width: 100%;
  position: relative;
  padding-bottom: 5px;
  .username, .body {
    padding-left: 10px;
  }
}

.tags {
  padding-left: 10px;
}

.comment {
  .timestamp {
    position: absolute;
    right: 5px;
  }
  .body, .tags {
    padding: 5px;
  }
}

.tags .tag {
  margin-right: 5px;
}

.panel {
  border-radius: 0;
  margin-bottom: 10px;
}

.panel-info {
  border-color: #adadad;
}

.panel-heading {
  padding: 5px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  h5 {
    margin: 0;
  }
  background: linear-gradient(#fff, #d3d3d3);
  color: #43494d;
  font-size: 14px;
}

.panel-info > .panel-heading {
  color: #43494d;
}

.list-group-item {
  &.active {
    padding: 5px 15px;
    border-radius: 0;
    &:hover, &:focus {
      padding: 5px 15px;
      border-radius: 0;
    }
    h5 {
      margin: 0;
    }
  }
  &:last-child {
    border-radius: 0;
  }
}

@media screen and (min-width: 1200px) {
  .pull-left-lg {
    float: left;
  }
  .pull-right-lg {
    float: right;
  }
}

@media (max-width: 992px) {
  .body {
    font-size: small;
  }
  .checkbox {
    margin: 0;
  }
  hr {
    margin: 5px 0;
  }
}

@media (min-width: 992px) {
  .center-md {
    text-align: center;
  }
  // .unauthenticatedContainer {
  //   -ms-background-size: 100%;
  //   background-size: 100%;
  // }
}

@media screen and (max-width: 767px) {
  .table-responsive {
    overflow-x: auto;
    > .table > {
      thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
        padding: 2px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .center-xs {
    text-align: center;
  }
  .panel-body-xs, .panel-heading-xs {
    padding: 0;
  }
}

.sideNavContainer {
  background-color: rgb(242, 244, 248);
  color: rgb(67, 73, 77);
  position: absolute;
  left: 92px;
  width: calc(100% - 92px);
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
  padding: 10px 0;
  height: 100%;

}

@media (max-width: 991px) {
  .sideNavContainer {
    left: 40px;
    width: calc(100% - 40px);
  }
}

.table > tbody > tr {
  > {
    th.border-right, td.border-right {
      border-right-color: #ccc;
    }
    th.border-top, td.border-top {
      border-top: 1px solid #ccc;
    }
  }
  &:last-child > td.border-bottom {
    border-bottom: 1px solid #ccc;
  }
  > {
    th, td {
      white-space: nowrap;
    }
  }
}

.bottom-margin-5 {
  margin-bottom: 5px;
}

.performance-value {
  font-size: 38px;
  font-weight: 400;
  margin-top: 0;
}

.performance-target {
  font-size: 12px;
}

.style-dollar, .style-percent {
  vertical-align: super;
  font-size: 27px;
}

@media screen and (max-width: 992px) {
  .performance-value {
    font-size: 30px;
    font-weight: 400;
  }
  .style-dollar, .style-percent {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .performance-value {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
  }
  .performance-target {
    padding: 0;
    margin: 0;
  }
  .style-dollar, .style-percent {
    font-size: 15px;
  }
}

.flotTip {
  position: absolute;
  display: none;
  border: 1px solid #ecf0f1;
  padding: 4px;
  background-color: #455A64;
  color: white;
  opacity: 0.85;
  z-index: 99999;
  width: auto;
  height: auto;
  border-radius    : 0.5em;
  white-space: nowrap;
}

.text-no-verflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-black {
  color: black;
}


.records-btn {
    padding: 3px 10px;
    margin-left: 20px;
}

.business-card {
      padding: 10px;
      width: 100%;
      min-height: 100px;
      background: white;
      box-shadow: 1px 1px 10px #999;
      overflow: hidden;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      color: #333;
      margin-bottom: 10px;
  }

.business-card .contactInfo {
    margin-bottom: 3px;
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus {
    background: white;
}
.panel.tab-content{
    border-top:none;
}

.colDividerLeft {
  border-left: 2px solid rgba(108,108,108,0.25) !important;
}

.colDividerRight {
  border-right: 2px solid rgba(108,108,108,0.25) !important;
}

.colDividerTop {
  border-top: 2px solid rgba(108,108,108,0.25) !important;
}

.colDividerBottom {
  border-bottom: 2px solid rgba(108,108,108,0.25) !important;
}

.pull-down {
  margin-top: 20px;
}
.space-around {
  margin-top: 22px;
}
.md-datepicker-calendar-pane.md-pane-open{
  z-index :999999;
}

._md-select-menu-container._md-active{
  z-index :9999;
}

.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.select2-drop-mask {
  z-index: 99998 !important;
}
.select2-drop {
  z-index: 99999 !important;
}
.error-list {
  max-height: 300px;
  overflow: auto;
}

.legal {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-y: scroll;
}
.legal-container {
  background: white;
  margin-top:20px;
  padding: 20px 10px;
}
.legal-container > img {
  margin-left: 20px;
}
.legal-contents {
  padding: 20px;
}
.legal-list {
  margin-bottom: 20px;
}
.legal-list li {
    margin-bottom: 10px;
}

.legal-snippet {
  .legal-list {
    margin-top: 20px;
  }
}

.legal-text {
  font-size: 1.4em;
  margin-top: 30px;
}

.legal-header {
  font-weight: bold;
}

.setting-checkbox {
  font-size:15px;
  padding-top:20px;
}

.legal-snippet {
  padding: 20px;
}

@media (max-width: 700px) {
  .legal-text {
    font-size: 1.0em;
  }

}

.exception-widget-column-header {
  padding: 0 !important;

}

@media (max-width: 1400px) {
 .exception-widget-column-font-size {
   font-size: 10px;
   padding: 0 !important;

 }
}

.exception-column-heading {
    padding: 0;

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1400px) {
  .exception-column-heading-container {
    padding: 0 !important;

  }
  .exception-column-heading {
     font-size: 0.7em;

   }
  .exception-column-value {
    font-size: 0.7em;
    padding: 0 !important;

  }
}

.tankSlides { 
  padding-top: 15px;
}
.slide-center {
  position: relative;
  margin: auto;
  margin-top: 10px;
}
/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;  
} 
/* Hide the images by default */
.mySlides {
  display: none;
  padding-top: 20px;
  padding-bottom: 20px;
} 
.slide-header {
  font-size: 20px; 
  padding-top: 20px;
}
/* Next & previous buttons */
.slide-prev, .slide-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px; 
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
} 
/* Position the "next button" to the right */
.slide-next {
  right: 0;
  border-radius: 3px 0 0 3px;
} 
/* On hover, add a black background color with a little bit see-through */
.slide-prev:hover, .slide-next:hover {
  background-color: rgba(0,0,0,0.8);
} 
/* Caption text */
.slide-text { 
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}  
.slide-numbertext { 
  color: #000;
  font-size: 12px;
  padding-top: 12px; 
} 
/* The dots/bullets/indicators */
.slide-dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
} 
.slide-active, .slide-dot:hover {
  background-color: #717171;
} 
/* Fading animation */
.slide-fade {
  animation-name: fade;
  animation-duration: 1.5s;
} 
@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}
.mb-m2 {
  margin-bottom: -2px;
}
.mb-m4 {
  margin-bottom: -4px;
}
.mb-m6 {
  margin-bottom: -6px;
}
.mb-m20 {
  margin-bottom: -20px;
}

.ng-hide.ng-hide-animate{
  display: none !important;
}

.view-all-container {
  min-height: 20px;
}

.modal-dialog {
  overflow-y: initial !important;
}

.modal-body {
  max-height: 80vh;
  overflow-y: auto;
}