body.minified div.side-nav {
  width: 40px;
}

div.side-nav {
  position: absolute;
  display: block;
  top: 0;
  height: 100%;
  bottom: 0;
  width: 95px;

  border-right: 1px solid #D5D5D5;
 @include gradient-horizontal(#fafafa, #e9e9e9);

  z-index: 98;
  -webkit-transition: width .5s ease-in-out;
  -moz-transition: width .5s ease-in-out;
  -ms-transition: width .5s ease-in-out;
  -o-transition: width .5s ease-in-out;
  transition: width .5s ease-in-out;
  ul.menu {
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    > li {
      position: relative;
      margin: 0;
      list-style: none;
      display: block;
      font-size: 11px;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      vertical-align: middle;
      -webkit-transition: all .5s linear;
      transition: all .5s linear;
      > a.active:hover {
        -webkit-transition: color .5s ease-in-out;
        -moz-transition: color .5s ease-in-out;
        -ms-transition: color .5s ease-in-out;
        -o-transition: color .5s ease-in-out;
        transition: color .5s ease-in-out;
      }
      &.active {
        &:before {
          border-color: rgba(204, 204, 204, 0);
          border-right-color: #E0E4E8;
          border-width: 11px;
          top: 50%;
          margin-top: -11px;
        }
        &:after, &:before {
          right: -1px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
      }
    }
  }
}

:before, :after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

div.side-nav ul.menu > li.active {
  &:after, &:before {
    right: -3px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(204, 204, 204, 0);
    border-right-color: #f2f4f8;
    border-width: 14px;
    top: 50%;
    margin-top: -14px;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
  }
}

body.minified div.side-nav ul.menu > li.active:after {
  border-width: 10px;
  margin-top: -10px;
}

div.side-nav ul.menu > li:first-child.active:after {
  border-right-color: #f2f4f8;
}

.minifyme {
  border-bottom: 1px solid #302F2F;
  background: #454545;
  color: #A8A8A8;
  text-align: center;
  padding: 0;
  width: 36px;
  height: 28px;
  display: block;
  position: absolute;
  right: 0px;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
  margin-top: 5px;
  font-size: 19px;
  -webkit-transition: all .1s linear 0s;
  transition: all .1s linear 0s;
}

.minified .minifyme {
  width: 100%;
  border-radius: 0;
  margin: 0;
  height: 34px;
  font-size: 23px;
}

.minifyme:hover {
  background: #646464;
  color: #E4E4E4;
  right: 0;
}

div.side-nav ul.menu > li {
  > a {
    display: block;
    margin: 0;
    padding: 10px 0 5px;
    height: 100%;
    width: 100%;
    //color: white;
    cursor: pointer;
    -webkit-transition: padding .5s ease-in-out;
    -moz-transition: padding .5s ease-in-out;
    -ms-transition: padding .5s ease-in-out;
    -o-transition: padding .5s ease-in-out;
    transition: padding .5s ease-in-out;
    &:hover {
      background-color: rgba(0, 0, 0, 0.15) !important;
      cursor: pointer;
    }
  }
  &.active > a {
    background-color: rgba(0, 0, 0, 0.15) !important;
    cursor: pointer;
  }
}

body.minified .side-nav ul.menu > li > a {
  padding: 20px 0 5px;
}

div.side-nav {
  a {
    color: inherit;
    text-decoration: none;
  }
  ul.menu > li a > .sidebar-icon {
    display: block;
    font-size: 24px;
    line-height: 1.2em;
    -webkit-transition: font-size .25s ease-in-out .5s;
    -moz-transition: font-size .25s ease-in-out .5s;
    -ms-transition: font-size .25s ease-in-out .5s;
    -o-transition: font-size .25s ease-in-out .5s;
    transition: font-size .25s ease-in-out .5s;
    > img {
      vertical-align: top;
    }
  }
}

body.minified .side-nav ul.menu > li a > .sidebar-icon {
  display: block;
  font-size: 16px;
  line-height: 1.2em;
  -webkit-transition: font-size .25s ease-in-out;
  -moz-transition: font-size .25s ease-in-out;
  -ms-transition: font-size .25s ease-in-out;
  -o-transition: font-size .25s ease-in-out;
  transition: font-size .25s ease-in-out;
}

.side-nav ul.menu > li a > .title {
  -webkit-transition: font-size .25s ease-in-out .5s;
  -moz-transition: font-size .25s ease-in-out .5s;
  -ms-transition: font-size .25s ease-in-out .5s;
  -o-transition: font-size .25s ease-in-out .5s;
  transition: font-size .25s ease-in-out .5s;
}

body.minified .side-nav ul.menu > li a > .title {
  font-size: 0;
  -webkit-transition: font-size .25s ease-in-out;
  -moz-transition: font-size .25s ease-in-out;
  -ms-transition: font-size .25s ease-in-out;
  -o-transition: font-size .25s ease-in-out;
  transition: font-size .25s ease-in-out;
}

.side-nav-close {
  float: right;
  color: white;
  position: absolute;
  top: 5px;
  left: 75px;
}

@media (max-width: 991px) {
  body {
    div.side-nav {
      width: 40px;
      ul.menu > li.active:after {
        border-width: 10px;
        margin-top: -10px;
      }
    }
    .side-nav ul.menu > li {
      > a {
        padding: 20px 0 5px;
      }
      a > .sidebar-icon {
        display: block;
        font-size: 16px;
        line-height: 1.2em;
        -webkit-transition: font-size .25s ease-in-out;
        -moz-transition: font-size .25s ease-in-out;
        -ms-transition: font-size .25s ease-in-out;
        -o-transition: font-size .25s ease-in-out;
        transition: font-size .25s ease-in-out;
      }
    }
  }
  .side-nav ul.menu > li a > .title {
    -webkit-transition: font-size .25s ease-in-out .5s;
    -moz-transition: font-size .25s ease-in-out .5s;
    -ms-transition: font-size .25s ease-in-out .5s;
    -o-transition: font-size .25s ease-in-out .5s;
    transition: font-size .25s ease-in-out .5s;
  }
  body .side-nav ul.menu > li a > .title {
    font-size: 0;
    -webkit-transition: font-size .25s ease-in-out;
    -moz-transition: font-size .25s ease-in-out;
    -ms-transition: font-size .25s ease-in-out;
    -o-transition: font-size .25s ease-in-out;
    transition: font-size .25s ease-in-out;
  }
  .side-nav-close {
    float: right;
    color: white;
    position: absolute;
    top: 3px;
    left: 25px;
  }
}