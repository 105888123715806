#authenticated {
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;

    main {
        flex: 1;
        overflow: hidden;
        position: relative;
    }
}
