.card .cardContent{
	height:150px;
}

.fullPageModalContainer {
    position: absolute;
    z-index : 6;
    top:0;
    bottom:0;
    left:0;
    right:0;
}

$widget-library-background : #eee;

.widget-library {
  background-color: $widget-library-background;


  & md-tab-content {
    @include transition(opacity .15s ease-in .15s);
    overflow-y : scroll;
  }

  & md-tab-content:not(.md-active) {
    @include opacity(0);
  }

  & md-tabs[md-border-bottom] {
    & md-tabs-wrapper{
      background-color: rgb(255,255,255);
    }
    & md-tabs-content-wrapper{
      background-color: $widget-library-background;

      & md-tab-content > div > md-content {
        background-color: $widget-library-background;
      }
    }
  }

}
