.mCustomScrollBox {
    position: static;
}

.mCSB_scrollTools .mCSB_draggerContainer {
    top: 20px;
    bottom: 20px;
}

.mCSB_container_wrapper {
    position: inherit;
    width: 100%;
}

.mCSB_inside > .mCSB_container {
    margin-right: 0;
}

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: rgba(255, 64, 129, 0.26);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #FF4081
}
.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
   background-color: #FF4081;
}
.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
   background-color: #FF4081;
}



.widget-card {
    background: #fff;
    margin-bottom: 30px;
    box-sizing: border-box;
    margin: 8px;
    @include box-shadow(0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12));
    background-color: rgb(255,255,255);
    border-radius: 2px;
}
.widget-card-header {
    position: relative;
    &:not(.ch-alt) {
        @media screen and (min-width: $screen-sm-min) {
            padding: 23px 25px;
        }
        @media screen and (max-width: $screen-sm-max) {
            padding: 18px;
        }
    }
    h2 {
        margin: 0;
        line-height: 100%;
        font-size: 17px;
        font-weight: 400;
        small {
            display: block;
            margin-top: 5px;
            color: #AEAEAE;
            line-height: 160%;
        }
    }
    &.ch-alt {
        @media screen and (min-width: $screen-sm-min) {
            padding: 20px 23px;
        }
        @media screen and (max-width: $screen-sm-max) {
            padding: 18px 18px 28px;
        }
        &:not([class*="bgm-"]) {
            background-color: #f7f7f7;
        }
    }
    &[class*="bgm-"] {
        h2, h2 small {
            color: #fff;
        }
    }
    .actions {
        position: absolute;
        right: 10px;
        z-index: 2;
        top: 15px;
    }
    .btn-float {
        right: 25px;
        bottom: -23px;
        z-index: 1;
    }
}
.widget-card-body {
    &.widget-card-padding {
        @media screen and (min-width: $screen-sm-min) {
            padding: 23px 25px;
        }
        @media screen and (max-width: $screen-sm-max) {
            padding: 18px;
        }
    }
    &.widget-card-padding-sm {
        padding: 15px;
    }
}
.widget-card-header:not(.ch-alt):not([class*="bgm-"]) + .widget-card-padding {
    padding-top: 0;
}
