ul.wizard {
  list-style: none;
  margin: 0px 0px 30px 0px;
  padding: 0px;
  position: relative;
  width: 100%;
  li {
    display: table-cell;
    text-align: center;
    width: 1%;
    .btn-link {
      text-decoration: none;
    }
    i {
      color: rgb(206, 209, 214);
    }
    &.workflow {
      &.first::before {
        left: 50%;
        max-width: 50%;
      }
      &.last::before {
        max-width: 50%;
        width: 50%;
      }
      &::before {
        border-top: 4px solid rgb(206, 209, 214);
        content: '';
        display: block;
        font-size: 0px;
        height: 1px;
        overflow: hidden;
        position: relative;
        top: 21px;
        width: 100%;
        z-index: 1;
      }
    }
    .step {
      background-color: rgb(255, 255, 255);
      border: 5px solid rgb(206, 209, 214);
      border-image-outset: initial;
      border-image-repeat: initial;
      border-image-slice: initial;
      border-image-source: initial;
      border-image-width: initial;
      border-radius: 100%;
      color: rgb(84, 100, 116);
      display: inline-block;
      font-size: 15px;
      height: 40px;
      line-height: 30px;
      position: relative;
      text-align: center;
      width: 40px;
      z-index: 2;
      &.liClick, &.liSubmit {
        border: 5px solid #5293C4;
        color: #5293C4;
      }
      &.liClick.success {
        border: 5px solid #5cb85c;
        color: #5cb85c;
      }
      &.liSubmit {
        background-color: #74B5E6;
      }
    }
    &.workflow {
      .step:hover, &.active .step:hover {
        background-color: #eeeeee;
      }
    }
    .step {
      &.liClick:hover {
        background-color: #eeeeee;
      }
      &.liSubmit:hover {
        border: 5px solid #4182B3;
        background-color: #63A4D5;
      }
      &.liClick i {
        color: rgb(84, 100, 116);
      }
      &.liSubmit i {
        color: #ffffff;
      }
    }
    &.workflow {
      &.active::before, &.complete::before, &.active .step {
        border-color: rgb(82, 147, 196);
      }
      &.complete .step {
        border-color: rgb(82, 147, 196);
        border-color: rgb(82, 147, 196);
      }
      &.valid .step::before {
        background-color: rgb(255, 255, 255);
        border-radius: 100%;
        bottom: 0px;
        color: rgb(135, 186, 33);
        content: "";
        display: block;
        font-family: FontAwesome;
        font-size: 17px;
        left: 0px;
        line-height: 30px;
        position: absolute;
        right: 0px;
        text-align: center;
        top: 0px;
        z-index: 3;
      }
    }
    .title {
      color: rgb(148, 158, 167);
      display: block;
      font-size: 14px;
      margin-top: 4px;
      max-width: 100%;
      table-layout: fixed;
      text-align: center;
      word-wrap: break-word;
      z-index: 104;
    }
    &.active .title {
      color: rgb(82, 147, 196);
      display: block;
    }
  }
}

.wizard-box-header {
  padding: 2px;
  border-bottom: none;
  width: 100%;
}

@media (max-width: 767px) {
  ul.wizard li {
    .title, &.unseen-xs {
      display: none;
    }
  }
}