.subscriber-info {
    text-align: center;
    font-size: 15px;
}

md-fab-speed-dial.md-fab-bottom-right.dash-switcher-fab  {
   z-index:6;
}

.dash-switcher-fab .text-capitalize {
  text-transform: capitalize; }
// .dash-switcher-fab .md-fab:hover, .dash-switcher-fab .md-fab.md-focused {
//   background-color: #000 !important; }
.dash-switcher-fab p.note {
  font-size: 1.2rem; }



.dashboard-nav {
  height:30px;
  @include gradient-vertical(#414141, #323232 );

  ul {
    text-align:center;
    > li {
      padding-top: 4px;
    }
  }
}

html[dir='rtl'] .trash > .lid {
  -webkit-transform-origin: 107% 100%;
}

.trash.text-success > .lid {
  -webkit-transform: rotate(-45deg);
  -webkit-transition: -webkit-transform 250ms;
}

html[dir='rtl'] .trash.text-success > .lid {
  -webkit-transform: rotate(45deg);
}

.trash {
  -webkit-appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  outline: none;
  padding: 0;
  position: relative;
  width: 30px;
  > {
    span {
      display: inline-block;
    }
    .can {
      background: url('../img/can.png') 0 0 no-repeat;
      left: 8px;
      position: inherit;
      right: 8px;
      top: 2px;
      margin: 5px 0 0 20px;
    }
    .lid {
      background: url('../img/can.png') 0 0 no-repeat;
      left: 8px;
      position: inherit;
      right: 8px;
      top: 2px;
      margin: 5px 0 0 20px;
      -webkit-transform-origin: -7% 100%;
      -webkit-transition: -webkit-transform 150ms;
      height: 6px;
      width: 14px;
    }
    .can {
      background-position: -1px -4px;
      height: 12px;
      margin: -2px 0 0 21px;
      width: 11px;
    }
  }
}

.sparks {
  display: block;
  list-style: none;
  margin: 13px 10px 11px 0;
  padding: 0;
  text-align: right;
  &.left {
    text-align: left;
  }
  li {
    &:first-child {
      border-left: 0;
      padding-left: 0;
    }
    border-left: 1px dotted #c7c7c7;
    padding: 0 10px;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    display: inline-block;
    margin-top: 6px;
    max-height: 47px;
    overflow: hidden;
    text-align: center;
  }
}

.sparks-info {
  min-width: 70px;
}

.txt-color-blue {
  color: #57889c !important;
}

.txt-color-green {
  color: #5cb85c !important;
}

.txt-color-white {
  color: #ffffff !important;
}

.txt-color-black {
  color: #000000 !important;
}

.sparks li h5 {
  border: 0;
  color: white;
  float: initial;
  font-size: 11px;
  font-weight: 400;
  margin: -3px 0 0 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
}

h5 {
  font-size: 17px;
  font-weight: 300;
  line-height: normal;
  margin: 10px 0;
}

.sparks {
  li span {
    color: #636363;
    display: block;
  }
  span i {
    font-size: 18px;
    line-height: 20px;
    margin: 0;
    text-transform: none;
  }
}

.sparks-info h5 i {
  font-size: 20px;
}

.page-title {
  margin: 8px 0 0 10px;
  span {
    color: #333;
    display: inline-block;
    font-size: 16px;
    vertical-align: 1px;
  }
  h1 {
    margin: 0;
    overflow: hidden;
    padding-bottom: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  div a.btn-link {
    padding: 0 5px;
  }
}

.responsive-input {
  height: 28px;
}

.page-email {
  /*color: #5cb85c !important;*/
  margin-left: 2px;
  padding: 0 0 10px 0;
  width: 100%;
  -moz-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  z-index: 900;
}

.dashboard-container {
  height: 100%;
  overflow: visible;
  position: absolute;
  width: 100%;
  @include opacity(1);

  & md-tab-content {
    @include transition(opacity .15s ease-in .15s);
    overflow-y : scroll;
  }

  & md-tab-content:not(.md-active) {
    @include opacity(0);
  }

  & md-tabs[md-border-bottom] md-tabs-wrapper{
    background-color: rgb(255,255,255);
  }
}
.dashboard-content {
  position: absolute;
  top: 0;
  bottom: 100px;
  left: 0;
  right: 0;
  background-color: #eee;
  height: 100%;

  & > .dashboard-tabs {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.dashboard-loading{
  position: absolute;
  height: 168px;
  width: 100%;
  top: 50%;
}

.dashboard-loading-content{
  position: relative;
  height: 100%;
  text-align:center;
  margin: 0 auto;
  top: -50%;
}

.dashboardScroller {
    /* height: 100%; */
    position: absolute;
    width: 100%;
    bottom: $header-height + $footer-height;
    // top: 30px;
    top: 0;
    overflow-y: scroll;
    overflow-x: hidden;
}




.dashboardsContainerOverlay {
  background: rgba(25, 25, 25, 0.5);
  height: 100%;
  left: 0;
  opacity: 1;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5000;
  &.inactive {
    opacity: 0;
    z-index: -1;
  }
}

body.minified .dashboardsContainer {
  margin-left: 40px;
  width: calc(100% - 40px);
}

.dashboardIconContainer {
  bottom: 0;
  height: 50px;
  overflow: visible;
  text-align: center;
}

.dashboardIcon {
  color: #333;
  cursor: pointer;
  display: inline-block;
  padding: 5px;
}

.dashboard {
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  // -moz-transition: opacity 1s;
  // -o-transition: opacity 1s;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  // -webkit-transition: opacity 1s;
  opacity: 0;
  position: absolute;
  width: 100%;
  &.active {
    opacity: 1;
  }
}

.dashboardSelection {
  height: 67px;
  .name {
    height: 25px;
    position: relative;
  }
}

.slightlyBiggerIconMarginMatch {
  padding: 2px 0;
  font-size: 14px;
  color: #e7e7e7;
  -webkit-transition: font-size .25s ease-in-out;
  -moz-transition: font-size .25s ease-in-out;
  -ms-transition: font-size .25s ease-in-out;
  -o-transition: font-size .25s ease-in-out;
  transition: font-size .25s ease-in-out;
  -webkit-transition: color .25s ease-in-out;
  -moz-transition: color .25s ease-in-out;
  -ms-transition: color .25s ease-in-out;
  -o-transition: color .25s ease-in-out;
  transition: color .25s ease-in-out;
  &:hover {
    color: white;
  }
}

.slightlyBiggerIcon {
  font-size: 16px;
  position: relative;
  top: 1px;
  color: white;
  padding: 0;
}


.movable {
  cursor: move;
}

.meerkat {
  background-image: url('../img/f5WrJKP.gif');
  background-position: center center;
  background-repeat: no-repeat;
  height: 400px;
}

.title-text {
  margin: 8px 0 0 10px;
}

.icon-bar {
  background-color: #888;
}

.edit-dash {
  float: right;
  margin-top: 8px;
  padding-right: 5px;
}

.remove-widget {
  div {
    margin: -6px 0 0 0;
    position: absolute;
    width: 100%;
  }
  i {
    margin-top: 10px;
  }
  .resize {
    font-size: 40px;
  }
}

.settings-dd-container {
  display: none;
  margin-top: -10px;
  position: absolute;
  right: 20px;
  z-index: 2000;
}

.settings-dd {
  display: block;
  margin: 2px 20px;
  position: relative;
  width: 318px;
}

.settings-list {
  list-style: none;
  margin: 5px 5px;
  padding-left: 0px;
  li {
    border-left: 1px dotted #c7c7c7;
    display: inline-block;
    padding: 0 10px;
    text-align: center;
    &:first-child {
      border-left: none;
    }
  }
}

.active {
  display: block;
}

div.disabled-content {
  opacity: 0.25;
}
// this is the start for the push notifications css

.pushNHeader{
  border-bottom: solid .5px rgba(230,230,230,1);
  margin-bottom:15px; 
  box-shadow: 0px 8px 35px -8px rgba(150,150,150,0.75);
  width: 100%;
  padding: 20px;
  background-color:rgb(220,220,220);
}
.pushNHeader button{
  margin-top: 5px;
}
.pushNTableHead{  
  font-size: 18px;
  background-image: none;
  margin-top: 20px;
  background-color:rgb(240,240,240);
}
.pushNTableHead table, thead, tr, th{
  background-image: none !important;
}
.pushNT {
  padding:10px 0 10px 25px !important;
}
.pushNTableBody td{
  width: 50%;
  padding-left: 20px;
  font-size: 16px;
  cursor: pointer;
}
.pushNTableBody tr:nth-child(even){
  background-color:rgb(240,240,240)
}
.pushNTableBody tr:nth-child(odd){
  background-color:rgb(230,230,230);
}
// .push{
//   width
// }
.sentToBox{
  width:90%;
  height:275px;
  border:solid 1px rgb(200,200,200);
  display: inline-block;
  overflow-y:auto;
  overflow-x:auto
}
.sentToBox::-webkit-scrollbar{
  width: 5px
}
.sentToBox::-webkit-scrollbar-track{
  background-color: rgb(230,230,230);
}
.sentToBox::-webkit-scrollbar-thumb{
  background-color: rgb(200,200,200);
}
.sentToBox ul{
  list-style: none;
  margin-left: -30px
}
.sentToBox li{
  //border-bottom: solid .75px rgb(200,200,200);
  margin-bottom: 10px
}
// this is the end for the push notifications css
@media (max-width: 767px) {
  .dashboardsContainer .page-title {
    margin-left: 0px;
    h1 {
      font-size: 18px;
      margin: 10px 0 5px 0;
      text-align: center;
    }
  }
  .dashboardSelection {
    padding-bottom: 5px;
  }
  .responsive-input {
    font-size: 12px;
    margin-bottom: 2px;
  }
  .hide-for-xs {
    display: none;
  }
  .sparks {
    margin-top: 7px;
    li.sparks-info {
      border-left: none;
      font-size: 25px;
      margin: 0 0 10px 0;
      overflow: visible;
      padding: 0px;
    }
  }
  .sparks-info h5 i {
    float: left;
  }
  .xs-no-padding {
    padding: 0;
  }
  .settings-dd {
    font-size: 12px;
    margin: 2px 4px;
    width: 277px;
  }
  .settings-dd-container {
    margin-top: -26px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .dashboardsContainer h1.page-title {
    font-size: 16px;
    margin-top: 10px;
  }
  .responsive-input {
    font-size: 10px;
  }
  .sparks li.sparks-info {
    border-left: none;
    margin: 0 0 10px 0;
    overflow: visible;
    padding: 0px;
  }
}

@media (min-width: 990px) and (max-width: 1200px) {
  .responsive-input {
    font-size: 12px;
  }
}
