@mixin transform-style($val) {
    -webkit-transform-style: $val;
    -ms-transform-style: $val; // IE9 only
    -moz-transform-style: $val;
    -o-transform-style: $val;
    transform-style: $val;
}
@mixin transform($val) {
    -webkit-transform: $val;
    -ms-transform: $val; // IE9 only
    -o-transform: $val;
    transform: $val;
}
@mixin transition($transition-property, $transition-time, $method) {
    -webkit-transition: $transition-property $transition-time $method;
    -moz-transition: $transition-property $transition-time $method;
    -ms-transition: $transition-property $transition-time $method;
    -o-transition: $transition-property $transition-time $method;
    transition: $transition-property $transition-time $method;
}

@mixin filter($filter-type, $filter-amount) {
    -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
    -moz-filter: $filter-type+unquote('(#{$filter-amount})');
    -ms-filter: $filter-type+unquote('(#{$filter-amount})');
    -o-filter: $filter-type+unquote('(#{$filter-amount})');
    filter: $filter-type+unquote('(#{$filter-amount})');
}
@function gradient-diag1-light($color) {
    @return lighten($color, 4%);
}
@function gradient-diag1-dark($color) {
    @return adjust_hue(darken($color, 3%), 20);
}
@function gradient-diag1-inverse-dark($color) {
    @return adjust_hue(darken($color, 3%), -15);
}
@mixin gradient-diag1-linear($light, $dark) {
    background-image: -moz-linear-gradient(-65deg, $light 40%, $dark);
    /* FF3.6+ */
    
    background-image: -webkit-gradient(linear, left top, right bottom, color-stop(40%, $light), color-stop(100%, $dark));
    /* Chrome,Safari4+ */
    
    background-image: -webkit-linear-gradient(-65deg, $light 40%, $dark);
    /* Chrome10+,Safari5.1+ */
    
    background-image: -o-linear-gradient(-65deg, $light 40%, $dark);
    /* Opera 11.10+ */
    
    background-image: -ms-linear-gradient(-65deg, $light 40%, $dark);
    /* IE10+ */
    
    background-image: linear-gradient(155deg, $light 40%, $dark);
    /* W3C */
}
@mixin gradient-diag1($color) {
    $light: gradient-diag1-light($color);
    $dark: gradient-diag1-dark($color);
    @include gradient-diag1-linear($light, $dark);
}
